import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Box,
  Flex,
  Icon,
  Link,
  Avatar,
  Text,
  VStack,
  Skeleton,
  // Image,
} from '@chakra-ui/react';
import { FiLifeBuoy, FiSettings, FiUsers } from 'react-icons/fi';
import { CiCreditCard1 } from 'react-icons/ci';
import { AiFillFileText } from 'react-icons/ai';
import { AvenirLogo } from 'components/icons/Icons';
import { CiLogout } from 'react-icons/ci';
import V1FreeTrailCard from './V1FreeTrailCard';
import {
  getStripePortalLink,
  firebaseLogout,
  // getClienHeaderDetails,
} from '../../../services/apiServices';

const V1SideNav = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const userInfo = useSelector((state) => state.usrinf);
  const userPlan = userInfo.plan.planName;
  const [stripePortalLink, setStripePortalLink] = useState('');
  // const [clientImage, setClientImage] = useState('');
  const pathMapping = {
    '/suitability-report/my-reports': 'my-reports',
    '/suitability-report/account-settings': 'account-settings',
    '/suitability-report/clients': 'clients',
    '/suitability-report/meetings': 'meetings',
  };
  const navigateToMyReports = () => {
    window.location.href = '/suitability-report/my-reports';
  };
  const navigateToAccountSettings = () => {
    navigate('/suitability-report/account-settings');
  };
  const navigateToMeetings = () => {
    navigate('/suitability-report/meetings');
  };
  const goToSubscribe = () => {
    navigate('/subscribe');
  };
  // const navigateToMyClients = () => {
  //   navigate('/suitability-report/clients');
  // };
  const goToBillings = () => {
    try {
      const res = stripePortalLink;
      if (res && res.url) {
        window.open(res.url, '_blank');
      } else {
        navigate('/subscribe');
      }
    } catch (error) {
      navigate('/subscribe');
    }
  };
  const userLogout = async () => {
    await firebaseLogout();
    window.location.reload();
  };
  const pathName = pathMapping[location.pathname] || 'my-reports';
  const fetchStripePortalLink = async () => {
    try {
      const res = await getStripePortalLink();
      setStripePortalLink(res);
    } catch (err) {
      // console.error('Error fetching Stripe portal link:', err);
    }
  };
  // const getHeaderImage = async () => {
  //   const res = await getClienHeaderDetails();
  //   setClientImage(res.headerImage);
  // };
  useEffect(() => {
    fetchStripePortalLink();
    // getHeaderImage();
  }, []);
  return (
    <Box w="20vw" h="100vh">
      <Flex
        w="98%"
        mt="20px"
        mb="15px"
        h="97%"
        justifyContent="center"
        alignItems="center"
      >
        <Flex
          direction="column"
          alignItems="center"
          bg="white"
          h="100%"
          w="90%"
          p="20px"
          borderRadius="30px"
          background="var(--White, #FFF)"
          boxShadow="14px 17px 40px 4px rgba(112, 144, 176, 0.08)"
        >
          <Flex
            mb="8"
            borderBottom="1px"
            borderColor="secondaryGray.400"
            w="100%"
            justifyContent="center"
          >
            <AvenirLogo h="46px" w="177px" mb="25px" />
          </Flex>

          {/* Navigation Links */}
          <VStack spacing="4" align="start" w="100%">
            <Link href="#" w="100%">
              <Flex align="center" onClick={navigateToMyReports}>
                <Icon
                  as={AiFillFileText}
                  boxSize="6"
                  mr="12px"
                  color={
                    pathName === 'my-reports'
                      ? 'avenirSuitabilityColor.800'
                      : '#A3AED0'
                  }
                />
                <Text
                  color={
                    pathName === 'my-reports'
                      ? 'avenirSuitabilityColor.800'
                      : '#A3AED0'
                  }
                  fontFamily="DM Sans"
                  fontSize="16px"
                  fontStyle="normal"
                  fontWeight="700"
                  lineHeight="30px"
                  letterSpacing="-0.32px"
                >
                  My Reports
                </Text>
              </Flex>
            </Link>
            {/* <Link href="#" w="100%">
              <Flex align="center" onClick={navigateToMyClients}>
                <Icon
                  as={FiUsers}
                  boxSize="6"
                  mr="12px"
                  color={
                    pathName === 'clients'
                      ? 'avenirSuitabilityColor.800'
                      : '#A3AED0'
                  }
                />
                <Text
                  color={
                    pathName === 'clients'
                      ? 'avenirSuitabilityColor.800'
                      : '#A3AED0'
                  }
                  fontFamily="DM Sans"
                  fontSize="16px"
                  fontStyle="normal"
                  fontWeight="700"
                  lineHeight="30px"
                  letterSpacing="-0.32px"
                >
                  My Clients
                </Text>
              </Flex>
            </Link> */}
            <Link href="#" w="100%">
              <Flex align="center" onClick={navigateToMeetings}>
                <Icon
                  as={FiUsers}
                  boxSize="6"
                  mr="12px"
                  color={
                    pathName === 'meetings'
                      ? 'avenirSuitabilityColor.800'
                      : '#A3AED0'
                  }
                />
                <Text
                  color={
                    pathName === 'meetings'
                      ? 'avenirSuitabilityColor.800'
                      : '#A3AED0'
                  }
                  fontFamily="DM Sans"
                  fontSize="16px"
                  fontStyle="normal"
                  fontWeight="700"
                  lineHeight="30px"
                  letterSpacing="-0.32px"
                >
                  Meetings
                </Text>
                <Text
                  sx={{
                    background: `conic-gradient(from 15.49deg at 69.3% 67.69%, 
                    #A33E75 -0.56deg, 
                    #FA6B25 36deg, 
                    #4E13C3 324deg, 
                    #A33E75 359.44deg, 
                    #FA6B25 396deg)`,
                    backgroundClip: 'text', // Clips background to text
                    textFillColor: 'transparent', // Makes the text transparent so the gradient is visible
                    WebkitBackgroundClip: 'text', // For WebKit browsers
                    WebkitTextFillColor: 'transparent', // For WebKit browsers
                  }}
                  ml="5px"
                  fontFamily="DM Sans"
                  fontSize="16px"
                  fontStyle="normal"
                  fontWeight="500"
                  lineHeight="30px"
                  letterSpacing="-0.32px"
                >
                  New
                </Text>
              </Flex>
            </Link>
            <Link
              href="https://calendly.com/avenir-andrea-macdonald/avenir-support"
              w="100%"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Flex align="center">
                <Icon as={FiLifeBuoy} boxSize="6" mr="12px" color="#A3AED0" />
                <Text
                  color="#A3AED0"
                  fontFamily="DM Sans"
                  fontSize="16px"
                  fontStyle="normal"
                  fontWeight="700"
                  lineHeight="30px"
                  letterSpacing="-0.32px"
                >
                  Support
                </Text>
              </Flex>
            </Link>
            <Link href="/suitability-report/account-settings" w="100%">
              <Flex align="center" onClick={navigateToAccountSettings}>
                <Icon
                  as={FiSettings}
                  boxSize="6"
                  mr="12px"
                  color={
                    pathName === 'account-settings'
                      ? 'avenirSuitabilityColor.800'
                      : '#A3AED0'
                  }
                />
                <Text
                  color={
                    pathName === 'account-settings'
                      ? 'avenirSuitabilityColor.800'
                      : '#A3AED0'
                  }
                  fontFamily="DM Sans"
                  fontSize="16px"
                  fontStyle="normal"
                  fontWeight="700"
                  lineHeight="30px"
                  letterSpacing="-0.32px"
                >
                  Settings
                </Text>
              </Flex>
            </Link>
            {userPlan !== 'Standard plan' ? (
              <Link href="#" w="100%" onClick={() => goToSubscribe()}>
                <Flex align="center">
                  <Icon
                    as={CiCreditCard1}
                    boxSize="6"
                    mr="12px"
                    color="#A3AED0"
                  />
                  <Text
                    color="#A3AED0"
                    fontFamily="DM Sans"
                    fontSize="16px"
                    fontStyle="normal"
                    fontWeight="700"
                    lineHeight="30px"
                    letterSpacing="-0.32px"
                  >
                    Subscribe
                  </Text>
                </Flex>
              </Link>
            ) : null}
            <Link href={stripePortalLink.url} target={stripePortalLink?.url ? '_blank' : '_self'} w="100%" onClick={() => goToBillings()}>
              <Flex align="center">
                <Icon
                  as={CiCreditCard1}
                  boxSize="6"
                  mr="12px"
                  color="#A3AED0"
                />
                <Text
                  color="#A3AED0"
                  fontFamily="DM Sans"
                  fontSize="16px"
                  fontStyle="normal"
                  fontWeight="700"
                  lineHeight="30px"
                  letterSpacing="-0.32px"
                >
                  Billings
                </Text>
              </Flex>
            </Link>
            <Link href="#" w="100%" onClick={() => userLogout()}>
              <Flex align="center">
                <Icon as={CiLogout} boxSize="6" mr="12px" color="#A3AED0" />
                <Text
                  color="#A3AED0"
                  fontFamily="DM Sans"
                  fontSize="16px"
                  fontStyle="normal"
                  fontWeight="700"
                  lineHeight="30px"
                  letterSpacing="-0.32px"
                >
                  Logout
                </Text>
              </Flex>
            </Link>
          </VStack>
          {/* Spacer */}
          <Box flex="1" />
          <V1FreeTrailCard w="100%"></V1FreeTrailCard>
          {userInfo.firstName?.length ? (
            <Flex align="center" mt="8" w="100%">
              {/* {clientImage?.length ? (
                <Image
                  src={clientImage}
                  objectFit="contain"
                  boxSize="48px"
                  border="1px solid"
                  borderRadius="50%"
                  borderColor="avenirGrey.100"
                  mr="2"
                ></Image>
              ) : ( */}
              <Avatar
                size="md"
                color="#2B3674"
                fontFamily="DM Sans"
                fontSize="16px"
                fontStyle="normal"
                fontWeight="700"
                lineHeight="30px"
                letterSpacing="-0.32px"
                name={`${userInfo.firstName} ${userInfo.lastName}`}
                mr="2"
              />
              {/* )} */}
              <Box>
                <Text
                  color="#2B3674"
                  fontFamily="DM Sans"
                  fontSize="16px"
                  fontStyle="normal"
                  fontWeight="700"
                  lineHeight="32px"
                  letterSpacing="-0.32px"
                >
                  {userInfo.firstName} {userInfo.lastName}
                </Text>
                <Text
                  color="#A3AED0"
                  fontFamily="DM Sans"
                  fontSize="16px"
                  fontStyle="normal"
                  fontWeight="500"
                  lineHeight="24px"
                  letterSpacing="-0.32px"
                >
                  {userInfo.organisationName}
                </Text>
              </Box>
            </Flex>
          ) : (
            <Flex mt="8" w="100%">
              <Skeleton
                isLoaded={false} // Replace false with your loading state
                height="48px"
                width="48px"
                borderRadius="full"
                startColor="gray.200"
                endColor="gray.400"
              />
              <Flex ml="10px" flexDirection="column">
                <Skeleton
                  isLoaded={false}
                  height="18px"
                  width="210px"
                  borderRadius="8px"
                  startColor="secondaryGray.100"
                  endColor="secondaryGray.300"
                />
                <Skeleton
                  isLoaded={false}
                  height="18px"
                  width="130px"
                  borderRadius="8px"
                  mt="6px"
                  startColor="secondaryGray.100"
                  endColor="secondaryGray.300"
                />
              </Flex>
            </Flex>
          )}
        </Flex>
      </Flex>
    </Box>
  );
};

export default V1SideNav;
