import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
  Box,
  Button,
  Flex,
  Input,
  InputGroup,
  InputLeftElement,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Text,
  useColorModeValue,
  Modal,
  ModalContent,
  ModalOverlay,
  Skeleton,
  InputRightElement,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  IconButton,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Spinner,
} from '@chakra-ui/react';
import { FiMoreVertical } from 'react-icons/fi';
import { SearchIcon } from '@chakra-ui/icons';
import { MdOutlineArrowBackIos } from 'react-icons/md';
import { MdOutlineArrowForwardIos } from 'react-icons/md';
// import actions
import { setUserClientList } from 'reducers/actions';
//import APi's
import {
  getUserReportList,
  getReportExportDoc,
  /*getReportExportPDF,*/
  getReportExportXls,
  getResetReport,
  getCanUserCreateReport,
  getUserClientList,
} from '../../../services/apiServices';

const ReportsOverview = ({ AsssignNewReportDetails, UpdateNewReportState }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userInfo = useSelector((state) => state.usrinf);
  const tableBg = useColorModeValue('white', 'gray.800');
  const separatorColor = useColorModeValue('gray.200', 'gray.600');
  // const items = [1, 2, 3, 4, 5, 6];
  const [showFreeDaysLeft, setShowFreeDaysLeft] = useState(false);
  const [reportsList, setReportsList] = useState();
  const [showReportList, setShowReportList] = useState(false);
  const [currentReportListNum, setCurrentReportListNum] = useState(1);
  const [reportSearchTearm, setReportSearchTearm] = useState('');
  const [showDeleterReport, setShowDeleterReport] = useState(false);
  const [deletedReportValue, setDeletedReportValue] = useState('');
  const [showExportingDoc, setShowExportingDoc] = useState(false);
  const [showCannotCreateModal, setShowCannotCreateModal] = useState(false);
  const monthNames = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];
  // Define the function to get the value based on screen height
  const getScreenHeightVariable = () => {
    const height = window.innerHeight;
    // Define your height breakpoints and return corresponding values
    if (height >= 1200) return 7; // Extra-large screens
    if (height >= 900) return 6; // Large screens
    if (height >= 600) return 5; // Medium screens
    return 4; // Small screens
  };
  // eslint-disable-next-line
  const [screenHeightVariable, setScreenHeightVariable] = useState(() => getScreenHeightVariable());
  const fetchCanUserCreateReport = async () => {
    try {
      const res = await getCanUserCreateReport();
      const parsedRes = JSON.parse(res);
      return parsedRes.canCreateReport;
    } catch (error) {
      console.error('Error checking user report creation ability:', error);
    }
  };
  const updateReportState = async () => {
    const res = await fetchCanUserCreateReport();
    if (res) {
      UpdateNewReportState(1);
    } else {
      setShowCannotCreateModal(true);
    }
  };
  const closeModal = () => {
    setShowCannotCreateModal(false);
  };
  const startFreetrail = () => {
    setShowFreeDaysLeft(false);
    // To set the value of isSFT to true in session storage
    sessionStorage.setItem('isSFT', true);
  };
  const handleSubscribe = () => {
    navigate('/subscribe');
  };
  const editReport = (reportUID, reportName, tempId) => {
    const res = {
      reportUID: {
        "reportUID": reportUID
      },
      templateUID: tempId,
      reportName: reportName,
    };
    AsssignNewReportDetails(res);
    UpdateNewReportState(2);
  };
  const handleSearchTermChange = async (e) => {
    setReportSearchTearm(e.target.value);
  };
  const changeCurrentReportPageList = async (val) => {
    let page = currentReportListNum + val;
    if (page >= 1) {
      const res = await getUserReportList(page, screenHeightVariable);
      setReportsList(res.reports);
      setCurrentReportListNum(page);
    }
  };
  const ChangeReportSearch = async () => {
    const res = await getUserReportList(
      1,
      screenHeightVariable,
      reportSearchTearm,
    );
    setReportsList(res.reports);
  };
  const clearReportList = async () => {
    setReportSearchTearm('');
    const res = await getUserReportList(1, screenHeightVariable);
    setReportsList(res.reports);
  };
  const deleteReport = (val) => {
    setDeletedReportValue(val);
    setShowDeleterReport(true);
  };
  const deleteReportFromModal = async () => {
    if (deletedReportValue) {
      setDeletedReportValue('');
      await getResetReport(deletedReportValue);
      const res = await getUserReportList(1, screenHeightVariable);
      setReportsList(res.reports);
      setShowDeleterReport(false);
    }
  };
  const exportReportDOC = async (reportID, reportName) => {
    try {
      setShowExportingDoc(true);
      const blob = await getReportExportDoc(reportID); // Get the Blob directly
      // Create a URL for the Blob and download it
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${reportName}.docx`); // Set the file name and extension
      document.body.appendChild(link);
      link.click();
      // Clean up
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url); // Revoke the object URL to free up memory
      setShowExportingDoc(false);
    } catch (error) {
      console.error('Error occurred while exporting report:', error);
    }
  };
  const exportReportXls = async (reportID, reportName) => {
    try{
      const res = await getReportExportXls(reportID);
      // Create a Blob URL for the XLS file
      const url = window.URL.createObjectURL(new Blob([res], { type: 'application/vnd.ms-excel' }));
      // Create a link element
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${reportName}.xlsx`); // Set the file name
      // Append the link to the body and trigger the download
      document.body.appendChild(link);
      link.click();
      // Clean up the link after download
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url); // Free up memory
    } catch(error) {
      console.error('Error occurred while exporting XLS report:', error);
    }
  }
  // const exportReportPDF = async (reportID, reportName) => {
  //   try {
  //     const blob = await getReportExportPDF(reportID); // Get the Blob directly
  //     // Create a URL for the Blob and download it
  //     const url = window.URL.createObjectURL(blob);
  //     const link = document.createElement('a');
  //     link.href = url;
  //     link.setAttribute('download', `${reportName}.pdf`); // Set the file name and extension to .pdf
  //     document.body.appendChild(link);
  //     link.click();
  //     // Clean up
  //     document.body.removeChild(link);
  //     window.URL.revokeObjectURL(url); // Revoke the object URL to free up memory
  //   } catch (error) {
  //     console.error('Error occurred while exporting report:', error);
  //   }
  // };
  useEffect(() => {
    const isSFT = sessionStorage.getItem('isSFT');
    if (userInfo?.plan?.trialRemainingDays) {
      let res =
        userInfo.plan.trialRemainingDays >= 13 &&
        userInfo.plan.trialRemainingHours >= 22
          ? true
          : false;
      if (res && isSFT === null) {
        setShowFreeDaysLeft(res);
      }
    }
  }, [userInfo]);
  useEffect(() => {
    async function fetchData() {
      try {
        const res = await getUserReportList(1, screenHeightVariable);
        setShowReportList(true);
        setReportsList(res.reports);
      } catch (error) {
        console.error('Error fetching user report list:', error);
      }
    }
    const fetchClientUserList = async () => {
      try {
        const res = await getUserClientList('', 200);
        dispatch(setUserClientList(res.clients));
      } catch (error) {
        console.error('Error getting user clients list', error);
      }
    }
    fetchData();
    fetchClientUserList()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Box
      w="80vw"
      bg="white"
      height="97%"
      mt="20px"
      mb="15px"
      borderRadius="30px"
    >
      <Flex
        flexDirection="column"
        height="100%"
        justifyContent="space-between"
        p={5}
      >
        <Box>
          <Flex width="100%" justifyContent="space-between" mb="20px">
            <Box>
              {userInfo.organisationName?.length ? (
                <Text sx={{ textStyle: 'dmsansNormal' }}>
                  {userInfo.organisationName} / Reports
                </Text>
              ) : (
                <Skeleton
                  mt="6px"
                  height="18px"
                  width="210px"
                  borderRadius="8px"
                  startColor="secondaryGray.100"
                  endColor="secondaryGray.300"
                />
              )}
              <Text sx={{ textStyle: 'dmsansHeading' }}>All Reports</Text>
            </Box>
            <Button
              colorScheme="purple"
              bg="avenirSuitabilityColor.600"
              sx={{ textStyle: 'dmsansText' }}
              borderRadius="12px"
              fontSize="14px"
              onClick={updateReportState}
            >
              Create New Report
            </Button>
          </Flex>
          <Flex mb="30px">
            <InputGroup w="30%" mr="10px">
              <InputLeftElement pointerEvents="none">
                <SearchIcon color="#2B3674" />
              </InputLeftElement>
              <Input
                value={reportSearchTearm}
                type="text"
                bg="secondaryGray.300"
                borderRadius="16px"
                fontSize="14px"
                placeholder="Search a report"
                focusBorderColor="avenirSuitabilityColor.600"
                onChange={handleSearchTermChange}
              />
              {reportSearchTearm.length ? (
                <InputRightElement
                  color="#909090"
                  sx={{ textStyle: 'dmsansNormal' }}
                  fontSize="14px"
                  fontWeight="400"
                  textDecorationLine="underline"
                  mr="10px"
                  mt="2px"
                  cursor="pointer"
                  onClick={() => clearReportList()}
                >
                  Clear
                </InputRightElement>
              ) : null}
            </InputGroup>
            <Button
              colorScheme="purple"
              sx={{ textStyle: 'dmsansText' }}
              bg="avenirSuitabilityColor.600"
              borderRadius="16px"
              fontSize="14px"
              onClick={() => ChangeReportSearch()}
            >
              Search Report
            </Button>
          </Flex>
          <Box overflowX="auto">
            <Table variant="simple" bg={tableBg} borderRadius="md">
              <Thead>
                <Tr borderBottom="1.5px solid" borderColor={separatorColor}>
                  <Th
                    sx={{ textStyle: 'dmsansNormal' }}
                    color="secondaryGray.600"
                  >
                    Report Title
                  </Th>
                  <Th
                    sx={{ textStyle: 'dmsansNormal' }}
                    color="secondaryGray.600"
                  >
                    Client Name
                  </Th>
                  <Th
                    sx={{ textStyle: 'dmsansNormal' }}
                    color="secondaryGray.600"
                  >
                    Date Created
                  </Th>
                  <Th
                    sx={{ textStyle: 'dmsansNormal' }}
                    color="secondaryGray.600"
                  >
                    Last Edited
                  </Th>
                  <Th
                    sx={{ textStyle: 'dmsansNormal' }}
                    color="secondaryGray.600"
                  >
                    Report Type
                  </Th>
                </Tr>
              </Thead>
              {showReportList ? (
                <Tbody>
                  {reportsList
                    .slice(0, screenHeightVariable)
                    .map((report, key) => {
                      const createdDate = new Date(report.createdDate);
                      const lastEditDate = new Date(report.lastEdit);

                      return (
                        <Tr
                          key={key}
                          borderBottom="1.5px solid"
                          borderColor={separatorColor}
                        >
                          <Td sx={{ textStyle: 'dmsansText' }}>
                            {report.reportName}
                          </Td>
                          <Td sx={{ textStyle: 'dmsansText' }}>
                            {report.clientFirstName} {report.clientLastName}
                          </Td>
                          <Td sx={{ textStyle: 'dmsansText' }}>
                            {`${
                              monthNames[createdDate.getMonth()]
                            } ${createdDate.getDate()}, ${createdDate.getFullYear()}`}
                          </Td>
                          <Td sx={{ textStyle: 'dmsansText' }}>
                            {`${
                              monthNames[lastEditDate.getMonth()]
                            } ${lastEditDate.getDate()}, ${lastEditDate.getFullYear()}`}
                          </Td>
                          <Td sx={{ textStyle: 'dmsansText' }}>
                            {report.reportType}
                          </Td>
                          <Td>
                            <Menu>
                              <MenuButton
                                as={IconButton}
                                icon={<FiMoreVertical />}
                                _hover={{ bg: 'white' }}
                                bg="white"
                                boxSize="44px"
                                border="0px !important"
                              />
                              <MenuList
                                border="1px"
                                borderColor="avenirGrey.200"
                                borderRadius="8px"
                                minWidth="auto" // Ensure that the menu list takes only the width required
                                pt="0"
                                pb="0"
                              >
                                <MenuItem
                                  _hover={{ bg: 'avenirGrey.100' }}
                                  px={4} // Padding for the items
                                  bg="white" // Ensure background stays white when not hovered
                                  borderRadius="10px 10px 0 0"
                                  fontFamily="DM Sans"
                                  fontWeight="500"
                                  justifyContent="flex-start"
                                  onClick={() =>
                                    editReport(
                                      report.reportUID,
                                      report.reportName,
                                      report.templateUID,
                                    )
                                  }
                                  variant="ghost" // Make the button look like a normal text link
                                  width="100%"
                                >
                                  Edit
                                </MenuItem>
                                {
                                  report.docExport ?
                                  <MenuItem
                                    _hover={{ bg: 'avenirGrey.100' }}
                                    px={4}
                                    bg="white"
                                    borderRadius="0 0 0px 0px"
                                    fontFamily="DM Sans"
                                    fontWeight="500"
                                    justifyContent="flex-start"
                                    onClick={() =>
                                      exportReportDOC(
                                        report.reportUID,
                                        report.reportName,
                                      )
                                    }
                                    variant="ghost"
                                    width="100%"
                                    minWidth="auto"
                                  >
                                    Export to Word
                                  </MenuItem> : null
                                }
                                 {
                                  report.excelExport ?
                                  <MenuItem
                                    _hover={{ bg: 'avenirGrey.100' }}
                                    px={4}
                                    bg="white"
                                    borderRadius="0 0 0px 0px"
                                    fontFamily="DM Sans"
                                    fontWeight="500"
                                    justifyContent="flex-start"
                                    onClick={() =>
                                      exportReportXls(
                                        report.reportUID,
                                        report.reportName
                                      )
                                    }
                                    variant="ghost"
                                    width="100%"
                                    minWidth="auto"
                                  >
                                    Export to Excel
                                  </MenuItem> : null
                                }
                                <MenuItem
                                  _hover={{ bg: 'avenirGrey.100' }}
                                  px={4}
                                  bg="white"
                                  borderRadius="0 0 10px 10px"
                                  fontFamily="DM Sans"
                                  fontWeight="500"
                                  justifyContent="flex-start"
                                  onClick={() => deleteReport(report.reportUID)}
                                  variant="ghost"
                                  width="100%"
                                  minWidth="auto"
                                >
                                  Delete Report
                                </MenuItem>
                              </MenuList>
                            </Menu>
                          </Td>
                        </Tr>
                      );
                    })}
                </Tbody>
              ) : (
                <Tbody>
                  {Array.from({ length: 6 }).map((_, sindex) => (
                    <Tr
                      key={sindex}
                      borderBottom="1.5px solid"
                      borderColor={separatorColor}
                      mt="10px"
                    >
                      {Array.from({ length: 6 }).map((_, index) => (
                        <Td key={index}>
                          <Skeleton
                            mt="6px"
                            height="14px"
                            width="120px"
                            borderRadius="8px"
                            startColor="secondaryGray.100"
                            endColor="secondaryGray.300"
                          />
                        </Td>
                      ))}
                    </Tr>
                  ))}
                </Tbody>
              )}
            </Table>
          </Box>
        </Box>
        <Flex justify="space-between" mt={5}>
          <Flex alignItems="center">
            {/* <Text sx={{ textStyle: 'dmsansNormal' }} mr="16px">
              Show rows per page
            </Text>
            <Menu>
              <MenuButton
                as={Button}
                rightIcon={<ChevronDownIcon />}
                bg="white"
                border="1px solid"
                borderColor="secondaryGray.200"
                sx={{ textStyle: 'dmsansNormal' }}
              >
                6
              </MenuButton>
              <MenuList bg="white">
                {items.map((item, index) => (
                  <MenuItem key={index}>{item}</MenuItem>
                ))}
              </MenuList>
            </Menu> */}
          </Flex>
          <Flex alignItems="center">
            <Flex>
              <Button
                leftIcon={<MdOutlineArrowBackIos />}
                onClick={() => changeCurrentReportPageList(-1)}
                bg="white"
              />
              <Button
                leftIcon={<MdOutlineArrowForwardIos />}
                onClick={() => changeCurrentReportPageList(1)}
                bg="white"
              />
            </Flex>
          </Flex>
        </Flex>
      </Flex>
      <Modal isOpen={showFreeDaysLeft} isCentered>
        <ModalOverlay />
        <ModalContent maxW="580px" borderRadius="15px">
          <Flex flexDirection="column" alignItems="center" p="29px">
            <Text
              sx={{ textStyle: 'dmsansText' }}
              fontSize="24px"
              color="#2B3674"
              fontWeight="700"
              mb={4}
            >
              🥳 Welcome to Avenir
            </Text>
            <Flex
              flexDirection="column"
              alignItems="center"
              mb={4}
              background="secondaryGray.300"
              p="16px 16px 12px 16px"
              borderRadius="15px"
            >
              <Text
                sx={{ textStyle: 'dmsansText' }}
                color="#1B2559"
                fontSize="34"
                fontWeight="700"
              >
                14
              </Text>
              <Text
                sx={{ textStyle: 'dmsansText' }}
                color="#A3AED0"
                fontSize="12px"
                fontWeight="500"
                mt="6px"
              >
                days
              </Text>
            </Flex>
            <Box textAlign="center" mb={4}>
              <Text
                mb="24px"
                sx={{ textStyle: 'dmsansText' }}
                color="#1B2559"
                fontSize="16px"
                fontWeight="400"
              >
                Your account has now been activated and you have a 14 day free
                trial. Start generating suitability letters in minutes! <br />{' '}
                Give us your feedback, we love hearing from you to improve our
                features.
              </Text>
              <Text
                sx={{ textStyle: 'dmsansText' }}
                color="#1B2559"
                fontSize="16px"
                fontWeight="400"
              >
                Once your trial ends, you can continue with a paid subscription.
              </Text>
            </Box>
            <Button
              sx={{ textStyle: 'dmsansText' }}
              fontSize="14px"
              width="150px"
              h="40px"
              border="1px solid"
              borderColor="avenirSuitabilityColor.600"
              color="white"
              bg="avenirSuitabilityColor.600"
              _hover={{ bg: 'avenirSuitabilityColor.600' }}
              onClick={startFreetrail}
            >
              Start Trial
            </Button>
          </Flex>
        </ModalContent>
      </Modal>
      <Modal isOpen={showDeleterReport} isCentered>
        <ModalOverlay />
        <ModalContent borderRadius="16px" maxW="500px">
          <ModalHeader
            sx={{ textStyle: 'dmsansNormal' }}
            fontSize="24px"
            fontWeight="700"
            color="#1B2559"
            mt="20px"
          >
            Are you sure?
          </ModalHeader>
          <ModalBody>
            <Text
              sx={{ textStyle: 'dmsansNormal' }}
              fontSize="16px"
              fontWeight="400"
              color="#1B2559"
            >
              The report and all associated data such as uploaded documents and
              extracted data will be deleted from Avenir.
            </Text>
          </ModalBody>

          <ModalFooter>
            <Button
              sx={{ textStyle: 'dmsansText' }}
              fontSize="14px"
              width="120px"
              h="40px"
              border="1px solid"
              borderColor="avenirSuitabilityColor.600"
              color="avenirSuitabilityColor.600"
              bg="white"
              mr="10px"
              _hover={{ bg: 'white' }}
              onClick={() => setShowDeleterReport(false)}
            >
              Cancel
            </Button>
            <Button
              sx={{ textStyle: 'dmsansText' }}
              fontSize="14px"
              width="120px"
              h="40px"
              border="1px solid"
              borderColor="avenirSuitabilityColor.600"
              color="white"
              bg="avenirSuitabilityColor.600"
              _hover={{ bg: 'avenirSuitabilityColor.600' }}
              onClick={() => {
                deleteReportFromModal();
              }}
            >
              Delete
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Modal isOpen={showExportingDoc} isCentered>
        <ModalOverlay />
        <ModalContent
          borderRadius="16px"
          maxW="400px"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <ModalBody
            display="flex"
            justifyContent="center"
            alignItems="center"
            m="20px"
          >
            <Flex alignItems="center">
              <Spinner
                thickness="4px"
                speed="0.65s"
                emptyColor="gray.200"
                color="avenirSuitabilityColor.600"
                size="xl"
              />
              <Text
                fontFamily="DM Sans"
                fontWeight="500"
                fontSize="lg"
                color="gray.600"
                ml="10px"
              >
                Exporting your report...
              </Text>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
      <Modal isOpen={showCannotCreateModal} isCentered>
        <ModalOverlay />
        <ModalContent border="1px" borderColor="avenirGrey.200">
          <ModalHeader
            sx={{ textStyle: 'dmsansNormal' }}
            fontSize="24px"
            fontWeight="700"
            color="#1B2559"
            mt="20px"
          >
            <Flex w="100%" justifyContent="center">
              Cannot create more reports
            </Flex>
          </ModalHeader>
          <ModalBody>
            <Flex w="100%" justifyContent="center" alignItems="center">
              <Text
                sx={{ textStyle: 'dmsansNormal' }}
                fontSize="16px"
                fontWeight="400"
                color="#1B2559"
                textAlign="center"
              >
                Your trial period has expired or you created too many reports
                for this month. Please switch to a paid plan.
              </Text>
            </Flex>
          </ModalBody>
          <ModalFooter>
            <Button
              sx={{ textStyle: 'dmsansText' }}
              fontSize="14px"
              width="120px"
              h="40px"
              border="1px solid"
              borderColor="avenirSuitabilityColor.600"
              color="white"
              bg="avenirSuitabilityColor.600"
              _hover={{ bg: 'avenirSuitabilityColor.600' }}
              onClick={handleSubscribe}
            >
              Subscribe
            </Button>
            <Button
              sx={{ textStyle: 'dmsansText' }}
              fontSize="14px"
              width="120px"
              h="40px"
              border="1px solid"
              borderColor="avenirSuitabilityColor.600"
              color="avenirSuitabilityColor.600"
              bg="white"
              ml="10px"
              _hover={{ bg: 'white' }}
              onClick={closeModal}
            >
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default ReportsOverview;
