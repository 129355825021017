import React, {useState} from 'react';
import { useSelector } from 'react-redux';
import {
  Box,
  Button,
  Flex,
  Text,
  Tag,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  Icon,
} from '@chakra-ui/react';
import { InfoOutlineIcon } from '@chakra-ui/icons';
import { FiAlertTriangle } from 'react-icons/fi';
import { IoArrowBack } from "react-icons/io5";

// Define a new component called PortfolioOptions
const PortfolioOptions = ({ changePortfolioState, myOwnGraph, goToBackQuestionFromPortfolio }) => {
  const currentQuestionId = useSelector((state) => state.questionId);
  const [isDisabled, setIsDisabled] = useState(false);
  const onClickOwnGraphs = () => {
    myOwnGraph()
  };
  const onClickGoToIsisn = () => {
    changePortfolioState();
  };
  const goback = () => {
    setIsDisabled(true);
    goToBackQuestionFromPortfolio();
    setTimeout(() => {
      setIsDisabled(false);
    }, 1000);
  }
  return (
    <Flex flexDirection="column" h="100%" justifyContent="space-between">
      <Box>
        {/* Title and description */}
        <Text
          sx={{ textStyle: 'manropeHeading' }}
          fontSize="18px"
          fontWeight="600"
          mb="10px"
        >
          Portfolio
        </Text>
        <Text
          color="avenirGrey.400"
          sx={{ textStyle: 'manropeText' }}
          fontSize="14px"
          fontWeight="500"
          mb="30px"
        >
          Choose between the two options to create your portfolio in your report.
        </Text>

        {/* First button with Recommended tag */}
        <Flex alignItems="center" mb={4}>
          <Box position="relative" display="inline-block">
            {/* Recommended Tag positioned on top of the button */}
            <Tag
              size="sm"
              colorScheme="orange"
              position="absolute"
              top="-8px"
              right="6px"
              px="10px"
              py="2px"
              zIndex="10"
              bg="white"
              color="avenirOrange.600"
              border="1px solid"
              borderColor="avenirOrange.600"
              borderRadius="12px"
              sx={{ textStyle: 'manropeText' }}
              fontSize="12px"
              fontWeight="400"
              p="2px 8px"
            >
              Recommended
            </Tag>

            {/* Button with right icon */}
            <Button
            w="195px"
          bg="avenirSuitabilityColor.600"
          color="white"
          fontSize="14"
          sx={{ textStyle: 'dmsansText' }}
          fontWeight="500"
          _hover={{ bg: 'avenirSuitabilityColor.600' }}
          p="22px 10px 22px 0"
          onClick={() => onClickOwnGraphs()} // Attach onClick to the button only
        >
          I have my own graphs
        </Button>

        {/* Separate Icon and Popover */}
        <Popover
          ml="15px"
          trigger="hover"
          placement="bottom-start"
          modifiers={[
            {
              name: 'offset',
              options: {
                offset: [0, 10], // Adjusts the offset of the popover
              },
            },
            {
              name: 'preventOverflow',
              options: {
                padding: 8, // Adds padding to prevent overflow issues
              },
            },
            {
              name: 'flip',
              options: {
                fallbackPlacements: ['bottom-start'], // Ensures it stays at bottom-start
              },
            },
          ]}
        >
          <PopoverTrigger>
            {/* Position Icon next to Button but not inside */}
            <Box
              position="absolute"
              top="47.5%"
              right="13px"
              transform="translateY(-50%)"
            >
              <Icon
                as={InfoOutlineIcon}
                w="16px"
                h="16px"
                color="white"
                _hover={{ color: 'white' }}
                cursor="pointer" // Ensures it looks interactive
              />
            </Box>
          </PopoverTrigger>
          <PopoverContent
            bg="white"
            color="black"
            border="1px solid"
            borderColor="gray.200"
            borderRadius="8px"
            boxShadow="1px 1px 1px 1px rgba(0, 0, 0, 0.20)"
            w="300px"
            h="80px"
            display="flex"
            alignItems="center"
            justifyContent="center"
            overflow="hidden"
            whiteSpace="normal"
            mt="5px"
          >
            <PopoverBody
              fontSize="12px"
              lineHeight="18px"
              fontWeight="400"
              sx={{ textStyle: 'interText' }}
              p="8px"
              pt="8px"
              overflowWrap="break-word"
              textAlign="left"
            >
              We will paste a placeholder in the template on the right side. You
              will be able to fill your portfolio there manually and go on with
              report creation.
            </PopoverBody>
          </PopoverContent>
        </Popover>
          </Box>
        </Flex>
        {/* Second button with outlined style */}
        <Box position="relative" display="inline-block">
        {/* Main Button */}
        <Button
          variant="outline"
          onClick={onClickGoToIsisn} // Attach onClick only to the button
          color="avenirSuitabilityColor.600"
          borderColor="avenirSuitabilityColor.600"
          borderRadius="16px"
          _hover={{ bg: 'white' }}
          fontSize="14"
          sx={{ textStyle: 'dmsansText' }}
          fontWeight="500"
          mt="10px"
          pl="0px"
          w="270px"
        >BETA: Generate graphs with Avenir</Button>

        {/* Separate Icon and Popover */}
        <Popover
          trigger="hover"
          placement="bottom-start"
          modifiers={[
            {
              name: 'offset',
              options: {
                offset: [0, 10], // Adjusts the offset of the popover
              },
            },
            {
              name: 'preventOverflow',
              options: {
                padding: 8, // Adds padding to prevent overflow issues
              },
            },
            {
              name: 'flip',
              options: {
                fallbackPlacements: ['bottom-start'], // Ensures it stays at bottom-start
              },
            },
          ]}
        >
          <PopoverTrigger>
            {/* Position Icon next to Button but not inside */}
            <Box
              position="absolute"
              top="56%"
              right="14px" // Adjust this value to make sure the icon is visible
              transform="translateY(-50%)"
              zIndex="1" // Ensure the icon is above other elements
            >
              <Icon
                as={InfoOutlineIcon}
                w="16px"
                h="16px"
                color="avenirSuitabilityColor.600"
                _hover={{ color: 'avenirSuitabilityColor.600' }}
                cursor="pointer" // Ensures it looks interactive
              />
            </Box>
          </PopoverTrigger>
          <PopoverContent
            bg="white"
            color="black"
            border="1px solid"
            borderColor="gray.200"
            borderRadius="8px"
            boxShadow="1px 1px 1px 1px rgba(0, 0, 0, 0.20)"
            w="330px"
            h="140px"
            display="flex"
            alignItems="center"
            justifyContent="center"
            overflow="hidden"
            whiteSpace="normal"
            mt="5px"
          >
            <PopoverBody
              fontSize="12px"
              lineHeight="18px"
              fontWeight="400"
              sx={{ textStyle: 'interText' }}
              p="8px"
              pt="8px"
              mt="10px"
              overflowWrap="break-word"
              textAlign="left"
              zIndex="10"
            >
              Avenir is now connected to a financial data provider. You will be
              able to select your ISINs and Avenir will automatically generate
              corresponding graphs in the report.
              <Flex alignItems="center" mt="6px" mb="6px">
                <Icon
                  as={FiAlertTriangle}
                  boxSize="24px"
                  color="avenirSuitabilityColor.600"
                />
                <Text ml="4px">
                  This feature is in Beta and you might encounter issues using it. You will be able to come back to this selection if needed.
                </Text>
              </Flex>
            </PopoverBody>
          </PopoverContent>
        </Popover>
      </Box>
      </Box>
      {
        currentQuestionId.previousQuestionId ? 
       <Button
        isDisabled={isDisabled}
        onClick={goback}
        bg="white"
        color="avenirSuitabilityColor.600"
        p="8px 18px"
        sx={{ textStyle: 'manropeHeading' }}
        fontSize="14px"
        _hover={{ bg: "white" }}
        border="1px solid"
        borderColor="avenirSuitabilityColor.600"
        borderRadius='12px'
        w="108px"
      >
        {<IoArrowBack style={{ fontSize: '24px', marginRight: '10px' }} />}
        Back
      </Button> : null
      }
    </Flex>
  );
};

export default PortfolioOptions;
