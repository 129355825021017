import { PublicClientApplication } from '@azure/msal-browser';

const msalConfig = {
  auth: {
    clientId: '726d88f0-74e8-4479-9867-b65fb8f38163',
    authority: 'https://login.microsoftonline.com/common',
    redirectUri: 'https://suitability.web.app/suitability-report/meetings', // or your production URL
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: true,
  },
};

const msalInstance = new PublicClientApplication(msalConfig);

const initializeMsalInstance = async () => {
  await msalInstance.initialize();
};

export { msalInstance, initializeMsalInstance };
