import React, {useState} from 'react';
import { useSelector } from 'react-redux';
import { Flex, Button } from '@chakra-ui/react';
//Icons
import { AiOutlineCheck } from 'react-icons/ai';
import { IoArrowBack } from "react-icons/io5";

export default function Default({ vallidateAnswer, skipQuestion, goToBackQuestion }) {
  const currentQuestionId = useSelector((state) => state.questionId);
  const [isDisabled, setIsDisabled] = useState(false);
  // const skipQuestions = () => {
  //   skipQuestion();
  // };
  const vallidateAnswers = () => {
    setIsDisabled(true);
    vallidateAnswer();
    setTimeout(() => {
      setIsDisabled(false);
    }, 1000);
  };
  const goback = () => {
    setIsDisabled(true);
    goToBackQuestion();
    setTimeout(() => {
      setIsDisabled(false);
    }, 1000);
  }
  return (
    <Flex alignItems="center" justifyContent={currentQuestionId.previousQuestionId ? "space-between" : "flex-end"} w="100%">
      {/* <Text
        onClick={skipQuestions}
        sx={{ textStyle: 'manropeHeading' }}
        color="avenirGrey.400"
        fontSize="14px"
        cursor="pointer"
      >
        Skip question
      </Text> */}
      {
        currentQuestionId.previousQuestionId ? 
       <Button
        isDisabled={isDisabled}
        onClick={goback}
        bg="white"
        color="avenirSuitabilityColor.600"
        p="8px 18px"
        sx={{ textStyle: 'manropeHeading' }}
        fontSize="14px"
        _hover={{ bg: "white" }}
        border="1px solid"
        borderColor="avenirSuitabilityColor.600"
        borderRadius='12px'
      >
        {<IoArrowBack style={{ fontSize: '24px', marginRight: '10px' }} />}
        Back
      </Button> : null
      }
      <Button
        isDisabled={isDisabled}
        onClick={vallidateAnswers}
        bg="avenirSuitabilityColor.600"
        color="white"
        p="8px 18px"
        sx={{ textStyle: 'manropeHeading' }}
        fontSize="14px"
        _hover={{ bg: "avenirSuitabilityColor.600" }}
        borderRadius='12px'
      >
        {<AiOutlineCheck style={{ fontSize: '24px', marginRight: '10px' }} />}
        OK
      </Button>
    </Flex>
  );
}
