import {
  Box,
  Text,
  Flex,
  Input,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  FormControl,
  FormLabel,
  ModalFooter,
} from '@chakra-ui/react';
import { useState } from 'react';
const V1CreateNewClient = ({ closeClient, createNewClient, createdClientName }) => {
  const [createClientFirstName, setCreateClientFirstName] = useState(createdClientName.firstname ? createdClientName.firstname : '');
  const [createClientLastName, setCreateClientLastName] = useState(createdClientName.lastname ? createdClientName.lastname : '');
  const [errorMessage, setErrorMessage] = useState('');
  const changeFirstName = (e) => {
    setCreateClientFirstName(e.target.value);
    setErrorMessage('');
  };
  const cahngeLastName = (e) => {
    setCreateClientLastName(e.target.value);
    setErrorMessage('');
  };
  const oncreateNewClient = () => {
    if (createClientFirstName.length && createClientLastName.length) {
      const res = {
        firstName: createClientFirstName,
        lastName: createClientLastName,
        mail: '',
      };
      createNewClient(res);
    } else {
      if (!createClientFirstName.length && !createClientLastName.length) {
        setErrorMessage('We could not add a new client. Please try again.');
      } else if (!createClientFirstName.length) {
        setErrorMessage('Please add a First Name.');
      } else if (!createClientLastName.length) {
        setErrorMessage('Please add a Last Name.');
      }
    }
  };
  return (
    <Box>
      <Modal isOpen={true} isCentered>
        <ModalOverlay />
        <ModalContent maxW="580px" maxH="300px" p="12px">
          <Box>
            <ModalHeader>
              <Text
                sx={{ textStyle: 'dmsansText' }}
                fontSize="24px"
                color="#2B3674"
                fontWeight="700"
              >
                Add New Client
              </Text>
            </ModalHeader>
            <ModalBody w="100%">
              <Flex>
                <FormControl id="first-name" mr={4}>
                  <FormLabel
                    sx={{ textStyle: 'dmsansText' }}
                    fontSize="16px"
                    color="#1B2559"
                    fontWeight="400"
                  >
                    First Name
                  </FormLabel>
                  <Input
                    value={createClientFirstName}
                    sx={{ textStyle: 'dmsansText' }}
                    fontSize="16px"
                    placeholder="John"
                    borderRadius="16px"
                    border="1px solid"
                    borderColor="secondaryGray.100"
                    width="250px"
                    focusBorderColor="avenirSuitabilityColor.600"
                    onChange={changeFirstName}
                  />
                </FormControl>
                <FormControl id="last-name">
                  <FormLabel
                    sx={{ textStyle: 'dmsansText' }}
                    fontSize="16px"
                    color="#1B2559"
                    fontWeight="400"
                  >
                    Last Name
                  </FormLabel>
                  <Input
                    value={createClientLastName}
                    sx={{ textStyle: 'dmsansText' }}
                    fontSize="16px"
                    placeholder="Doe"
                    borderRadius="16px"
                    border="1px solid"
                    borderColor="secondaryGray.100"
                    w="250px"
                    focusBorderColor="avenirSuitabilityColor.600"
                    onChange={cahngeLastName}
                  />
                </FormControl>
              </Flex>
            </ModalBody>
            <ModalFooter mt="20px">
              <Flex flexDirection="column" alignItems="flex-end">
                <Box mb="4px">
                  <Text sx={{ textStyle: 'manropeHeading' }} color="#B20D30" fontSize="12px" fontWeight="600">{errorMessage}</Text>
                </Box>
                <Box>
                  <Button
                    sx={{ textStyle: 'dmsansText' }}
                    fontSize="14px"
                    width="120px"
                    h="40px"
                    border="1px solid"
                    borderColor="avenirSuitabilityColor.600"
                    color="avenirSuitabilityColor.600"
                    bg="white"
                    mr="10px"
                    _hover={{ bg: 'white' }}
                    onClick={closeClient}
                  >
                    Cancel
                  </Button>
                  <Button
                    sx={{ textStyle: 'dmsansText' }}
                    fontSize="14px"
                    width="120px"
                    h="40px"
                    border="1px solid"
                    borderColor="avenirSuitabilityColor.600"
                    color="white"
                    bg="avenirSuitabilityColor.600"
                    _hover={{ bg: 'avenirSuitabilityColor.600' }}
                    onClick={oncreateNewClient}
                  >
                    Create Client
                  </Button>
                </Box>
              </Flex>
            </ModalFooter>
          </Box>
        </ModalContent>
      </Modal>
    </Box>
  );
};
export default V1CreateNewClient;
