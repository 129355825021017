import React, { useState } from 'react';
import { Box, Flex, Text, Button } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { AiOutlineDownload } from 'react-icons/ai';
import { IoArrowBack } from 'react-icons/io5';
import { getReportExportXls } from '../../../services/apiServices';
export default function Default({
  question,
  userAnswered,
  generatePdfOrDocQuestion,
  goToPreviousQuestion,
}) {
  const currentQuestionId = useSelector((state) => state.questionId);
  const SuitabilityProgress = useSelector((state) => state.spr);
  const currentReportUID = useSelector((state) => state.reportUID);
  const exportFullScreen = {
    isFullScreen:
      SuitabilityProgress.excelExport &&
      !SuitabilityProgress.PDFExport &&
      !SuitabilityProgress.docExport
        ? true
        : false,
  };
  const [isDisabled, setIsDisabled] = useState(false);
  const clickedGeneratePdfOrDOc = (value) => {
    generatePdfOrDocQuestion(value);
  };
  const goback = () => {
    setIsDisabled(true);
    goToPreviousQuestion();
    setTimeout(() => {
      setIsDisabled(false);
    }, 1000);
  };
  const exportReportXls = async () => {
    try {
      const res = await getReportExportXls(currentReportUID.reportUID);
      // Create a Blob URL for the XLS file
      const url = window.URL.createObjectURL(
        new Blob([res], { type: 'application/vnd.ms-excel' }),
      );
      // Create a link element
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${currentReportUID.reportName}.xlsx`); // Set the file name
      // Append the link to the body and trigger the download
      document.body.appendChild(link);
      link.click();
      // Clean up the link after download
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url); // Free up memory
    } catch (error) {
      console.error('Error occurred while exporting XLS report:', error);
    }
  };
  return (
    <Flex
      flexDirection="column"
      justifyContent={
        exportFullScreen.isFullScreen ? undefined : 'space-between'
      }
      h="100%"
    >
      <Flex
        h={exportFullScreen.isFullScreen ? 'max-content' : '100%'}
        overflow="hidden"
        flexDirection="column"
        w="100%"
      >
        <Box>
          <Flex justifyContent="space-between" alignItems="center">
            <Text sx={{ textStyle: 'manropeHeading' }} fontSize="18px">
              {question.Text}
            </Text>
          </Flex>
          <Text
            sx={{ textStyle: 'manropeHeading' }}
            fontSize="14px"
            color="avenirGrey.400"
            lineHeight="16px"
            fontWeight="400"
            mt="8px"
          >
            {question.SubText}
          </Text>
        </Box>
        {!exportFullScreen.isFullScreen ? (
          <Flex gap="16px" mt="30px">
            {SuitabilityProgress.PDFExport ? (
              <Button
                leftIcon={<AiOutlineDownload style={{ fontSize: '24px' }} />}
                colorScheme="avenirSuitabilityColor"
                bg="avenirSuitabilityColor.800"
                variant="solid"
                p="12px 18px"
                borderRadius="10px"
                h="46px"
                _hover={{
                  bg: 'avenirSuitabilityColor.800',
                }}
                onClick={() => clickedGeneratePdfOrDOc('PDF')}
              >
                PDF
              </Button>
            ) : null}
            {SuitabilityProgress.docExport ? (
              <Button
                leftIcon={<AiOutlineDownload style={{ fontSize: '24px' }} />}
                colorScheme="avenirSuitabilityColor"
                bg="avenirSuitabilityColor.800"
                variant="solid"
                p="12px 18px"
                borderRadius="10px"
                h="46px"
                _hover={{
                  bg: 'avenirSuitabilityColor.800',
                }}
                onClick={() => clickedGeneratePdfOrDOc('DOC')}
              >
                DOC
              </Button>
            ) : null}
            {SuitabilityProgress.excelExport ? (
              <Button
                leftIcon={<AiOutlineDownload style={{ fontSize: '24px' }} />}
                colorScheme="avenirSuitabilityColor"
                bg="avenirSuitabilityColor.800"
                variant="solid"
                p="12px 18px"
                borderRadius="10px"
                h="46px"
                _hover={{
                  bg: 'avenirSuitabilityColor.800',
                }}
                onClick={() => exportReportXls()}
              >
                Excel
              </Button>
            ) : null}
          </Flex>
        ) : null}
      </Flex>
      <Flex>
        {currentQuestionId.previousQuestionId ? (
          <Button
            isDisabled={isDisabled}
            onClick={goback}
            bg="avenirSuitabilityColor.600"
            color="white"
            p="12px 18px"
            sx={{ textStyle: 'manropeHeading' }}
            fontSize="14px"
            h="46px"
            _hover={{ bg: 'avenirSuitabilityColor.600' }}
            borderRadius="12px"
          >
            {<IoArrowBack style={{ fontSize: '24px', marginRight: '10px' }} />}
            Back
          </Button>
        ) : null}
        {exportFullScreen.isFullScreen ? (
          <Button
            leftIcon={<AiOutlineDownload style={{ fontSize: '24px' }} />}
            colorScheme="avenirSuitabilityColor"
            bg="avenirSuitabilityColor.800"
            variant="solid"
            p="12px 18px"
            borderRadius="10px"
            h="46px"
            _hover={{
              bg: 'avenirSuitabilityColor.800',
            }}
            onClick={() => exportReportXls()}
            ml="20px"
          >
            Excel
          </Button>
        ) : null}
      </Flex>
    </Flex>
  );
}
