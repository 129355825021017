import React, { useState, useEffect } from 'react';
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Textarea,
  Select,
  Box,
  Flex,
  Text,
  IconButton,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  PopoverBody,
  Divider,
  Alert,
  AlertIcon,
} from '@chakra-ui/react';
import { InfoOutlineIcon } from '@chakra-ui/icons';
import { FiAlertTriangle } from 'react-icons/fi';
import { NumericFormat } from 'react-number-format';
import SkipValidateAnswer from './SkipValidateAnswer';

const MultiColumnTableAnswer = ({ question, userAnswered, goToPreviousQuestion }) => {
  const [userAnswer, setUserAnswer] = useState({});
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    // Combine categoryNames and answers to create a single object with isError: false
    const combinedData = question.categoryNames.flatMap((category, index) =>
      question.answers[index]?.map((answer) => ({
        [answer.fieldName]: {
          answer: answer.AIAnswer,
          isError: false, // Initialize error state
          regex: answer.regex || '', // Add regex pattern if it exists
        },
      })),
    );
    // Convert the array of objects into a single object
    const mergedData = combinedData.reduce((acc, curr) => {
      return { ...acc, ...curr }; // Merge each object into a single dictionary
    }, {});
    // Set the merged data to state
    setUserAnswer(mergedData);
  }, [question]);

  const skipQuestion = () => {
    console.log('skipQuestion');
  };
  const goToBackQuestion = () => {
    goToPreviousQuestion()
  }

  const vallidateAnswer = () => {
    // Iterate over each field in userAnswer and validate against the regex
    const validatedAnswers = Object.entries(userAnswer).map(([key, value]) => {
      // Ensure regex is defined, if not, default to an empty string
      const regexPattern = value.regex || '';

      // Validate the existing answer against its regex pattern
      const isValid = new RegExp(regexPattern).test(value.answer);

      // Return the updated object with isError set based on regex validation, keeping regex intact
      return [
        key,
        {
          ...value,
          isError: !isValid,
          regex: regexPattern, // Ensure regex is retained and not undefined
        },
      ];
    });

    // Convert the array of updated entries back into an object
    const updatedUserAnswer = Object.fromEntries(validatedAnswers);
    setUserAnswer(updatedUserAnswer);

    // Check if any field has isError set to true
    const hasError = Object.values(updatedUserAnswer).some(
      (field) => field.isError,
    );

    if (hasError) {
      // Set error message based on error type using the updated state
      const isRequiredError = Object.values(updatedUserAnswer).some(
        (field) => field.isError && field.answer === '',
      );
      if (isRequiredError) {
        setErrorMessage('Please answer all required fields');
      } else {
        setErrorMessage('Check format of your answer');
      }
    } else {
      // If no errors, proceed with the userAnswered function
      setErrorMessage(''); // Clear any existing error messages
      // Convert updatedUserAnswer into the desired format with only the answer values
      const formattedUserAnswer = Object.fromEntries(
        Object.entries(updatedUserAnswer).map(([key, value]) => [
          key,
          value.answer,
        ]),
      );
      userAnswered(formattedUserAnswer);
    }
  };

  const handleInputChange = (fieldName, value, regexs) => {
    // Create a copy of the existing userAnswer object
    setErrorMessage('');
    const newUserAnswer = { ...userAnswer };

    // Validate the input against the regex pattern
    const isValid = new RegExp(regexs).test(value);

    // Update the specific field with the new value and error status
    newUserAnswer[fieldName] = {
      answer: value,
      isError: !isValid,
      regex: regexs,
    };

    // Set the updated object back to the state
    setUserAnswer(newUserAnswer);
  };

  return (
    <Flex flexDirection="column" h="80vh" overflow="auto" p="10px 30px 30px 30px">
      <Flex>
        <Text sx={{ textStyle: 'manropeHeading' }} fontSize="18px">
          {question.text}
        </Text>
      </Flex>
      <Text
        sx={{ textStyle: 'manropeHeading' }}
        fontSize="12px"
        color="avenirGrey.400"
        lineHeight="16px"
        fontWeight="400"
        mb="16px"
        mt="8px"
      >
        {question.subText}
      </Text>
      <Box>
        <TableContainer
          borderRadius="8px"
          borderBottom="1px solid #E2E8F0"
          borderLeft="1px solid #E2E8F0"
          w="max-content"
        >
          <Table
            variant="simple"
            sx={{
              '& th': {
                borderRight: '1px solid #E2E8F0',
                padding: '5px 8px',
                textStyle: 'manropeHeading',
                color: 'white',
                fontSize: '14px',
              },
              '& td': {
                borderRight: '1px solid #E2E8F0',
                padding: '5px 8px',
                textStyle: 'manropeHeading',
                color: '#1B2559',
                fontSize: '14px',
                fontWeight: '400',
              },
              '& tr:last-child td': {
                borderBottom: 'none',
              },
            }}
          >
            <Thead bg="avenirSuitabilityColor.800">
              <Tr>
                <Th textTransform="none" w="200px">{question.tableName}</Th>
                {question.answers[0].map((tableName, index) => (
                  <Th key={index} color="white" textTransform="none" w="200px">
                    {tableName.fieldName}
                  </Th>
                ))}
              </Tr>
            </Thead>
            <Tbody>
              {question.answers.map((rowSection, index) => (
                <Tr
                  key={index}
                  bg={index % 2 !== 0 ? 'avenirGrey.100' : 'white'}
                  h="max-content"
                >
                  <Td>{question.categoryNames[index]}</Td>
                  {rowSection.map((rowElement, rowElementIndex) => (
                    <Td key={rowElementIndex}>
                      {!rowElement.enum.length ? (
                        <Flex>
                          {rowElement.type === 'float' ? (
                          <NumericFormat
                            thousandSeparator={true}
                            suffix={rowElement.symbol === '%' ? rowElement.symbol : '' }
                            prefix={rowElement.symbol !=='%' ? rowElement.symbol : '' }
                            inputMode={
                              rowElement.type === 'integer' ? 'numeric' : 'text'
                            }
                            customInput={Textarea}
                            value={
                              userAnswer[rowElement.fieldName]?.answer || '' // Access using the dynamic key from rowElement
                            }
                            placeholder={rowElement.placeholder}
                            onValueChange={(values) =>
                              handleInputChange(
                                rowElement.fieldName,
                                values.value,
                                rowElement.regex, // Pass the regex for validation
                              )
                            }
                            size="sm"
                            borderColor={
                              userAnswer[rowElement.fieldName]?.isError
                                ? 'red.500'
                                : index % 2 !== 0
                                ? 'avenirGrey.100'
                                : 'white'
                            }
                            _hover={{
                              borderColor:
                                index % 2 !== 0 ? 'avenirGrey.100' : 'white',
                            }}
                            _focus={{
                              borderColor:
                                index % 2 !== 0 ? 'avenirGrey.100' : 'white',
                            }}
                            _focusVisible={{
                              borderColor:
                                index % 2 !== 0 ? 'avenirGrey.100' : 'white',
                            }}
                            width="200px" // Fixed width
                            overflow="hidden" // Hide scrollbar
                            resize="none" // Prevent manual resizing by the user
                            rows={1} // Initial number of rows
                            minHeight="unset"
                            onInput={(e) => {
                              // Dynamically adjust the height of the Textarea based on its content
                              e.target.style.height = 'auto'; // Reset the height first
                              e.target.style.height = `${e.target.scrollHeight}px`; // Set height based on scroll height
                            }}
                          /> ) : (
                          <Textarea
                            value={
                              userAnswer[rowElement.fieldName]?.answer || '' // Access using the dynamic key from rowElement
                            }
                            placeholder={rowElement.placeholder}
                            onChange={(e) =>
                              handleInputChange(
                                rowElement.fieldName,
                                e.target.value,
                                rowElement.regex, // Pass the regex for validation
                              )
                            }
                            size="sm"
                            borderColor={
                              userAnswer[rowElement.fieldName]?.isError
                                ? 'red.500'
                                : index % 2 !== 0
                                ? 'avenirGrey.100'
                                : 'white'
                            }
                            _hover={{
                              borderColor:
                                index % 2 !== 0 ? 'avenirGrey.100' : 'white',
                            }}
                            _focus={{
                              borderColor:
                                index % 2 !== 0 ? 'avenirGrey.100' : 'white',
                            }}
                            _focusVisible={{
                              borderColor:
                                index % 2 !== 0 ? 'avenirGrey.100' : 'white',
                            }}
                            width="200px" // Fixed width
                            overflow="hidden" // Hide scrollbar
                            resize="none" // Prevent manual resizing by the user
                            rows={1} // Initial number of rows
                            minHeight="unset"
                            onInput={(e) => {
                              // Dynamically adjust the height of the Textarea based on its content
                              e.target.style.height = 'auto'; // Reset the height first
                              e.target.style.height = `${e.target.scrollHeight}px`; // Set height based on scroll height
                            }}
                          /> )}
                          {rowElement.AIAnswer && (
                            <Popover trigger="hover" placement="top-start">
                              <PopoverTrigger>
                                <IconButton
                                  variant="ghost"
                                  aria-label="Information"
                                  icon={<InfoOutlineIcon />}
                                  size="sm"
                                  borderRadius="full"
                                  color="avenirSuitabilityColor.200"
                                  cursor="default"
                                />
                              </PopoverTrigger>
                              <PopoverContent
                                maxW="350px"
                                maxH="250px"
                                overflow="auto"
                                p={1}
                              >
                                <PopoverArrow />
                                <PopoverBody>
                                  <Box>
                                    <Text
                                      sx={{ textStyle: 'interText' }}
                                      fontWeight="700"
                                    >
                                      Source:
                                    </Text>
                                    <Box
                                      sx={{
                                        textStyle: 'manropeText',
                                        fontSize: '12px',
                                        overflowWrap: 'break-word',
                                        wordBreak: 'break-word',
                                        whiteSpace: 'normal',
                                      }}
                                      dangerouslySetInnerHTML={{
                                        __html: rowElement.AISource,
                                      }}
                                    />
                                    {rowElement.AISourceType === 'Infered' && (
                                      <Flex
                                        alignItems="center"
                                        mt="6px"
                                        mb="6px"
                                      >
                                        <FiAlertTriangle color="#CEA2FC" />
                                        <Text
                                          sx={{ textStyle: 'interText' }}
                                          fontSize="9px"
                                          fontWeight="400"
                                          color="#000"
                                          lineHeight="12px"
                                          ml="4px"
                                        >
                                          This information was inferred by the
                                          AI, meaning it is not a direct quote
                                          from the documents.
                                        </Text>
                                      </Flex>
                                    )}
                                    <Divider
                                      orientation="horizontal"
                                      borderColor="avenirGrey.200"
                                      borderWidth="1px"
                                      m="5px 0"
                                    />
                                    <Flex sx={{ textStyle: 'interText' }}>
                                      <Text fontWeight="700">Document: </Text>
                                      <Text fontWeight="500" ml="2">
                                        {rowElement.AIDocument}
                                      </Text>
                                    </Flex>
                                  </Box>
                                </PopoverBody>
                              </PopoverContent>
                            </Popover>
                          )}
                        </Flex>
                      ) : (
                        <Flex>
                          <Select
                            value={
                              userAnswer[rowElement.fieldName]?.answer // Check if the object exists before accessing
                            }
                            onChange={(e) =>
                              handleInputChange(
                                rowElement.fieldName,
                                e.target.value,
                                rowElement.regex, // Pass the regex for validation
                              )
                            }
                            size="sm"
                            borderColor={
                              userAnswer[rowElement.fieldName]?.isError
                                ? 'red.500'
                                : index % 2 !== 0
                                ? 'avenirGrey.100'
                                : 'white'
                            }
                          >
                            {/* Add a placeholder option with empty value */}
                            <option value="" disabled hidden>
                              Select an option
                            </option>
                            {Array.isArray(rowElement.enum) &&
                              rowElement.enum
                                .filter(
                                  (option) =>
                                  option.trim() !== '' && option !== '""""' && option !== '""' && option !== '"' && option !== '-'
                                )
                                .map((option, i) => (
                                  <option key={i} value={option}>
                                    {option}
                                  </option>
                                ))}
                          </Select>
                          {rowElement.AIAnswer && (
                            <Popover trigger="hover" placement="top-start">
                              <PopoverTrigger>
                                <IconButton
                                  variant="ghost"
                                  aria-label="Information"
                                  icon={<InfoOutlineIcon />}
                                  size="sm"
                                  borderRadius="full"
                                  color="avenirSuitabilityColor.200"
                                  cursor="default"
                                />
                              </PopoverTrigger>
                              <PopoverContent
                                maxW="350px"
                                maxH="250px"
                                overflow="auto"
                                p={1}
                              >
                                <PopoverArrow />
                                <PopoverBody>
                                  <Box>
                                    <Text
                                      sx={{ textStyle: 'interText' }}
                                      fontWeight="700"
                                    >
                                      Source:
                                    </Text>
                                    <Box
                                      sx={{
                                        textStyle: 'manropeText',
                                        fontSize: '12px',
                                        overflowWrap: 'break-word',
                                        wordBreak: 'break-word',
                                        whiteSpace: 'normal',
                                      }}
                                      dangerouslySetInnerHTML={{
                                        __html: rowElement.AISource,
                                      }}
                                    />
                                    {rowElement.AISourceType === 'Infered' && (
                                      <Flex
                                        alignItems="center"
                                        mt="6px"
                                        mb="6px"
                                      >
                                        <FiAlertTriangle color="#CEA2FC" />
                                        <Text
                                          sx={{ textStyle: 'interText' }}
                                          fontSize="9px"
                                          fontWeight="400"
                                          color="#000"
                                          lineHeight="12px"
                                          ml="4px"
                                        >
                                          This information was inferred by the
                                          AI, meaning it is not a direct quote
                                          from the documents.
                                        </Text>
                                      </Flex>
                                    )}
                                    <Divider
                                      orientation="horizontal"
                                      borderColor="avenirGrey.200"
                                      borderWidth="1px"
                                      m="5px 0"
                                    />
                                    <Flex sx={{ textStyle: 'interText' }}>
                                      <Text fontWeight="700">Document: </Text>
                                      <Text fontWeight="500" ml="2">
                                        {rowElement.AIDocument}
                                      </Text>
                                    </Flex>
                                  </Box>
                                </PopoverBody>
                              </PopoverContent>
                            </Popover>
                          )}
                        </Flex>
                      )}
                    </Td>
                  ))}
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      </Box>
      <Flex flexDirection="row-reverse" mt="20px" justifyContent="flex-end">
        {errorMessage ? (
          <Alert
            status="error"
            bg="#FED7D7"
            borderRadius="10px"
            sx={{ textStyle: 'manropeHeading' }}
            fontWeight="500"
            w="350px"
            ml="auto" // Pushes the Alert to the right
          >
            <AlertIcon color="#E53E3E" />
            {errorMessage}
          </Alert>
        ) : null}
      </Flex>

      <Flex flexDirection="row-reverse" mt="20px">
        <SkipValidateAnswer
          skipQuestion={skipQuestion}
          vallidateAnswer={vallidateAnswer}
          goToBackQuestion={goToBackQuestion}
        />
      </Flex>
    </Flex>
  );
};

export default MultiColumnTableAnswer;
