import React, { useState } from 'react';
import {
  Box,
  Flex,
  Text,
  Table,
  Tr,
  Tbody,
  Td,
  Input,
  Alert,
  AlertIcon,
  InputGroup,
  InputRightElement,
  IconButton,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  Portal,
  Divider,
  Select
} from '@chakra-ui/react';
import { InfoOutlineIcon } from '@chakra-ui/icons';
import { FiAlertTriangle } from 'react-icons/fi';
import { NumericFormat } from 'react-number-format';
import '../../../assets/css/App.css';
//Import Skip and Validate Answer component
import SkipValidateAnswer from './SkipValidateAnswer';
export default function Default({ question, userAnswered, goToPreviousQuestion }) {
  const [showErrorState, setShowErrorState] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [answers, setAnswers] = useState(
    question.answers.reduce((acc, item) => {
      acc[item.fieldName] = item.AIAnswer ?? '';
      return acc;
    }, {}),
  );
  const [error, setError] = useState(
    question.answers.reduce((acc, item) => {
      acc[item.fieldName] = false;
      return acc;
    }, {}),
  );
  // eslint-disable-next-line no-unused-vars
  const [ansRegx, setAnsRegx] = useState(
    question.answers.reduce((acc, item) => {
      acc[item.fieldName] = item.regex;
      return acc;
    }, {}),
  );
  // Function to check if any field has a false value
  const hasFalseValue = (currentError) => {
    let res = Object.entries(currentError).some(([key, value]) => {
      if (!answers[key]?.length) {
        setErrorMessage(' Please answer all  required fields');
      } else {
        setErrorMessage('Check format of your answer');
      }
      return value === true;
    });
    setShowErrorState(res);
    return res;
  };
  const vallidateAnswer = async () => {
    const promises = Object.keys(answers).map((key) => {
      let keyVal = answers[key] ? answers[key] : '';
      return checkTableAnswer(keyVal, key, ansRegx[key]);
    });
    const results = await Promise.all(promises);
    // Combine the results into a single error object
    const combinedError = results.reduce(
      (acc, curr) => ({ ...acc, ...curr }),
      {},
    );
    if (!hasFalseValue(combinedError)) {
      userAnswered(answers);
    }
  };
  const skipQuestion = () => {
    console.log('skipQuestion');
  };
  const goToBackQuestion = () => {
    goToPreviousQuestion()
  }
  const checkTableAnswer = (e, fieldName, regex) => {
    return new Promise((resolve) => {
      const val = e;
      const regex_t = new RegExp(regex);
      if (!regex_t.test(val)) {
        setError((prev) => {
          const newError = { ...prev, [fieldName]: true };
          resolve(newError);
          return newError;
        });
      } else {
        resolve();
      }
    });
  };
  const handleInputChange = (e, fieldName, regex) => {
    const val = e;
    const regex_t = new RegExp(regex);
    if (regex_t.test(val)) {
      setAnswers((prev) => ({
        ...prev,
        [fieldName]: val,
      }));
      setError((prev) => ({
        ...prev,
        [fieldName]: false,
      }));
    } else {
      setAnswers((prev) => ({
        ...prev,
        [fieldName]: val,
      }));
      setError((prev) => ({
        ...prev,
        [fieldName]: true,
      }));
    }
    setShowErrorState(false);
  };
  return (
    <Flex
      h="100%"
      overflow="scroll"
      flexDirection="column"
      justifyContent="space-between"
      w="100%"
      className="hide-scrollbar"
    >
      <Box>
        <Flex>
          <Text sx={{ textStyle: 'manropeHeading' }} fontSize="18px">
            {question.text}
          </Text>
        </Flex>
        <Text
          sx={{ textStyle: 'manropeHeading' }}
          fontSize="12px"
          color="avenirGrey.400"
          lineHeight="16px"
          fontWeight="400"
        >
          {question.subText}
        </Text>
        <Box mt="20px" w="100%">
          <Table
            variant="simple"
            size="sm"
            borderWidth="1px"
            borderColor="avenirGrey.400"
          >
            <Tbody>
              {question.answers.map((Answer, index) => (
                <Tr key={index}>
                  <Td
                    borderWidth="1px"
                    w="40%"
                    borderColor="avenirGrey.400"
                    bg="avenirGrey.100"
                    sx={{ textStyle: 'manropeHeading' }}
                    fontSize="12px"
                  >
                    {Answer.fieldName}
                  </Td>
                  <Td borderWidth="1px" w="60%" borderColor="avenirGrey.400">
                    <InputGroup>
                    <Flex w="80%">
                      {!Answer.enum.length ? (
                        Answer.type === 'float' ? (
                          <NumericFormat
                            thousandSeparator={true}
                            suffix={Answer.symbol === '%' ? Answer.symbol : '' }
                            prefix={Answer.symbol !=='%' ? Answer.symbol : '' }
                            inputMode={
                              Answer.type === 'integer' ? 'numeric' : 'text'
                            }
                            customInput={Input}
                            value={answers[Answer.fieldName]}
                            placeholder={Answer.placeholder}
                            border={!error[Answer.fieldName] ? '0px' : '1px'}
                            borderColor={
                              !error[Answer.fieldName] ? '#4E13C3' : '#E4103F'
                            }
                            onValueChange={(values) =>
                              handleInputChange(
                                values.value,
                                Answer.fieldName,
                                Answer.regex,
                              )
                            }
                            // onWheel={(e) => e.target.blur()} // Optional, to prevent scroll wheel from changing input values
                          />
                        ) : (
                          <Input
                            type={Answer.type === 'integer' ? 'number' : 'text'}
                            value={answers[Answer.fieldName]}
                            placeholder={Answer.placeholder}
                            border={!error[Answer.fieldName] ? '0px' : '1px'}
                            borderColor={
                              !error[Answer.fieldName] ? '#4E13C3' : '#E4103F'
                            }
                            onChange={(e) =>
                              handleInputChange(
                                e.target.value,
                                Answer.fieldName,
                                Answer.regex,
                              )
                            }
                            onWheel={(e) => e.target.blur()} // Optional, to prevent number input scroll
                          />
                        )
                      ) : (
                        <Select
                          value={answers[Answer.fieldName]}
                          w="100%"
                          size="sm"
                          borderRadius="0px"
                          borderColor={
                            !error[Answer.fieldName] ? 'white' : '#E4103F'
                          }
                          _hover={{
                            borderColor: '#4E13C3'
                          }}
                          _focusVisible={{
                            borderColor: '#4E13C3'
                          }}
                          onChange={(e) =>
                            handleInputChange(
                              e.target.value,
                              Answer.fieldName,
                              Answer.regex,
                            )
                          }
                        >
                          <option value="" disabled hidden>
                            Select an option
                          </option>
                          {Array.isArray(Answer.enum) &&
                            Answer.enum
                              .filter(
                                (option) =>
                                  option.trim() !== '' &&
                                  option !== '""""' &&
                                  option !== '""' &&
                                  option !== '"' &&
                                  option !== '-',
                              )
                              .map((option, i) => (
                                <option key={i} value={option}>
                                  {option}
                                </option>
                              ))}
                        </Select>
                      )}
                      {Answer.AIAnswer ? (
                        <InputRightElement  top="-3px">
                          <Popover
                            trigger="hover"
                            placement="right-end" // Set the initial placement to right-end
                            offset={[0, 10]}
                          >
                            <PopoverTrigger>
                              <IconButton
                                variant="ghost"
                                aria-label="Information"
                                icon={<InfoOutlineIcon />}
                                size="sm"
                                borderRadius="full"
                                color={
                                  Answer.AISource
                                    ? 'avenirSuitabilityColor.200'
                                    : 'avenirGrey.200'
                                }
                                _hover={{
                                  color: Answer.AISource
                                    ? 'avenirSuitabilityColor.200'
                                    : 'avenirGrey.200',
                                }}
                                cursor="default"
                              />
                            </PopoverTrigger>
                            <Portal>
                              <PopoverContent
                                bg="white"
                                shadow="md"
                                w="290px"
                                zIndex={1500}
                                borderRadius="15px"
                                border="1.5px solid"
                                borderColor={
                                  Answer.AIDocument && Answer.AISource
                                    ? 'avenirSuitabilityColor.200'
                                    : 'avenirGrey.200'
                                }
                                modifiers={[
                                  {
                                    name: 'flip',
                                    options: {
                                      fallbackPlacements: [
                                        'top-end',
                                        'right-end',
                                        'bottom-end',
                                      ], // Ensures it stays aligned to the right
                                    },
                                  },
                                  {
                                    name: 'preventOverflow',
                                    options: {
                                      boundary: 'viewport',
                                      tether: false,
                                    },
                                  },
                                ]}
                              >
                                <PopoverBody borderRadius="10px">
                                  {Answer.AIDocument && Answer.AISource ? (
                                    <Box>
                                      <Text
                                        sx={{ textStyle: 'interText' }}
                                        fontWeight="700"
                                      >
                                        Source:
                                      </Text>
                                      <Box
                                        sx={{
                                          textStyle: 'manropeText',
                                          fontSize: '12px',
                                        }}
                                        dangerouslySetInnerHTML={{
                                          __html: Answer.AISource,
                                        }}
                                      />
                                      {Answer.AISourceType === 'Infered' ? (
                                        <Flex
                                          alignItems="center"
                                          mt="6px"
                                          mb="6px"
                                        >
                                          <FiAlertTriangle color="#CEA2FC" />
                                          <Text
                                            sx={{ textStyle: 'interText' }}
                                            fontSize="9px"
                                            fontWeight="400"
                                            color="#000"
                                            lineHeight="12px"
                                            ml="4px"
                                          >
                                            This information was inferred by the
                                            AI, meaning it is not a direct quote
                                            from the documents.
                                          </Text>
                                        </Flex>
                                      ) : null}
                                      <Divider
                                        orientation="horizontal"
                                        borderColor="avenirGrey.200"
                                        borderWidth="1px"
                                        m="5px 0"
                                      />
                                      <Flex sx={{ textStyle: 'interText' }}>
                                        <Text fontWeight="700">Document: </Text>
                                        <Text fontWeight="500" ml="2">
                                        {Answer.AIDocument === 'string' || Answer.AIDocument.replace(/\s+/g, '') === 'string' ? 'Unknown' : Answer.AIDocument.substring(0, 20)}
                                        </Text>
                                      </Flex>
                                    </Box>
                                  ) : (
                                    <Box>
                                      <Text sx={{ textStyle: 'interText' }}>
                                        Source not available for this data
                                      </Text>
                                    </Box>
                                  )}
                                </PopoverBody>
                              </PopoverContent>
                            </Portal>
                          </Popover>
                        </InputRightElement>
                      ) : null}
                      </Flex>
                    </InputGroup>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </Box>
      </Box>
      <Flex flexDirection="row-reverse" mt="10px">
        {showErrorState ? (
          <Alert
            status="error"
            bg="#FED7D7"
            borderRadius="10px"
            sx={{ textStyle: 'manropeHeading' }}
            fontWeight="500"
          >
            <AlertIcon color="#E53E3E" />
            {errorMessage}
          </Alert>
        ) : (
          <SkipValidateAnswer
            skipQuestion={skipQuestion}
            vallidateAnswer={vallidateAnswer}
            goToBackQuestion={goToBackQuestion}
          />
        )}
      </Flex>
    </Flex>
  );
}
