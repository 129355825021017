import React, { useState, useRef, useEffect } from 'react';
import { Box, Flex, Text, Textarea, Alert, AlertIcon, Popover, PopoverTrigger, IconButton, Portal, PopoverBody, PopoverContent, Divider } from '@chakra-ui/react';
import { InfoOutlineIcon } from '@chakra-ui/icons';
import { FiAlertTriangle } from 'react-icons/fi';
import SkipValidateAnswer from './SkipValidateAnswer';

export default function Default({ question, userAnswered, goToPreviousQuestion }) {
  const [text, setText] = useState(
    question.answers[0].AIAnswer ? question.answers[0].AIAnswer : '',
  );
  const textareaRef = useRef(null);
  const [showErrorState, setShowErrorState] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const handleInputChange = (e) => {
    setShowErrorState(false);
    setText(e.target.value);
    setTextAreaHeight();
  };
  const setTextAreaHeight = () => {
    const textarea = textareaRef.current;
    if (textarea) {
      // Reset height to auto to recalculate height
      textarea.style.height = 'auto';
      // Set the height to the scrollHeight with constraints
      const newHeight = Math.min(
        textarea.scrollHeight,
        window.innerHeight * 0.5,
      );
      textarea.style.height = `${newHeight}px`;
    }
  };
  const vallidateAnswer = () => {
    let hasError = false;
    if (!text.length) {
      hasError = true;
      setShowErrorState(true);
      setErrorMessage(' Please answer the required fields');
    }
    const regex_t = new RegExp(question.answers[0].regex);
    if (!regex_t.test(text)) {
      hasError = true;
      setShowErrorState(true);
      setErrorMessage('Check format of your answer');
    }
    if (!hasError) {
      let userans = {
        default: text,
      };
      userAnswered(userans);
    }
  };
  const skipQuestion = () => {
    console.log('skipQuestion');
  };
  const goToBackQuestion = () => {
    goToPreviousQuestion()
  }
  useEffect(() => {
    setTextAreaHeight();
  }, []);
  return (
    <Flex
      h="100%"
      overflow="hidden"
      flexDirection="column"
      justifyContent="space-between"
      w="100%"
    >
      <Box>
        <Flex justifyContent="space-between" alignItems="center">
          <Text sx={{ textStyle: 'manropeHeading' }} fontSize="18px">
            {question.text}
          </Text>
        </Flex>
        <Text
          sx={{ textStyle: 'manropeHeading' }}
          fontSize="14px"
          color="avenirGrey.400"
          lineHeight="16px"
          fontWeight="400"
          mt="8px"
        >
          {question.subText}
        </Text>
        <Flex justifyContent="space-between" alignItems="end" mt="24px">
          <Textarea
            id="cause-input"
            ref={textareaRef}
            value={text}
            placeholder={question.answers[0].placeholder}
            size="lg"
            borderRadius="20px"
            w="98%"
            resize="none"
            overflow="auto"
            _placeholder={{
              fontSize: 'md',
              color: 'gray.400',
            }}
            pt="10px"
            pb="10px"
            ml="3px"
            onChange={handleInputChange}
            borderColor={
              showErrorState ? '#E4103F !important' : '#E6E6E6 !important'
            }
            border="1px"
          />
        </Flex>
        {question.answers[0].AIAnswer ? (
          <Flex alignItems="center" mt="12px">
            <Text sx={{textStyle: 'manropeText'}} fontWeight="400" fontSize="14px">Source:</Text>
            <Popover
              trigger="hover"
              placement="right-end" // Set the initial placement to right-end
              offset={[0, 10]}
            >
              <PopoverTrigger>
                <IconButton
                  variant="ghost"
                  aria-label="Information"
                  icon={<InfoOutlineIcon />}
                  size="sm"
                  borderRadius="full"
                  color={
                    question.answers[0].AISource
                      ? 'avenirSuitabilityColor.200'
                      : 'avenirGrey.200'
                  }
                  _hover={{
                    color: question.answers[0].AISource
                      ? 'avenirSuitabilityColor.200'
                      : 'avenirGrey.200',
                  }}
                  cursor="default"
                />
              </PopoverTrigger>
              <Portal>
                <PopoverContent
                  bg="white"
                  shadow="md"
                  w="290px"
                  zIndex={1500}
                  borderRadius="15px"
                  border="1.5px solid"
                  borderColor={
                    question.answers[0].AIDocument && question.answers[0].AISource
                      ? 'avenirSuitabilityColor.200'
                      : 'avenirGrey.200'
                  }
                  modifiers={[
                    {
                      name: 'flip',
                      options: {
                        fallbackPlacements: [
                          'top-end',
                          'right-end',
                          'bottom-end',
                        ], // Ensures it stays aligned to the right
                      },
                    },
                    {
                      name: 'preventOverflow',
                      options: {
                        boundary: 'viewport',
                        tether: false,
                      },
                    },
                  ]}
                >
                  <PopoverBody borderRadius="10px">
                    {question.answers[0].AIDocument && question.answers[0].AISource ? (
                      <Box>
                        <Text sx={{ textStyle: 'interText' }} fontWeight="700">
                          Source:
                        </Text>
                        <Box
                          sx={{
                            textStyle: 'manropeText',
                            fontSize: '12px',
                          }}
                          dangerouslySetInnerHTML={{
                            __html: question.answers[0].AISource,
                          }}
                        />
                        {question.answers[0].AISourceType === 'Infered' ? (
                          <Flex alignItems="center" mt="6px" mb="6px">
                            <FiAlertTriangle color="#CEA2FC" />
                            <Text
                              sx={{ textStyle: 'interText' }}
                              fontSize="9px"
                              fontWeight="400"
                              color="#000"
                              lineHeight="12px"
                              ml="4px"
                            >
                              This information was inferred by the AI, meaning
                              it is not a direct quote from the documents.
                            </Text>
                          </Flex>
                        ) : null}
                        <Divider
                          orientation="horizontal"
                          borderColor="avenirGrey.200"
                          borderWidth="1px"
                          m="5px 0"
                        />
                        <Flex sx={{ textStyle: 'interText' }}>
                          <Text fontWeight="700">Document: </Text>
                          <Text fontWeight="500" ml="2">
                            {question.answers[0].AIDocument}
                          </Text>
                        </Flex>
                      </Box>
                    ) : (
                      <Box>
                        <Text sx={{ textStyle: 'interText' }}>
                          Source not available for this data
                        </Text>
                      </Box>
                    )}
                  </PopoverBody>
                </PopoverContent>
              </Portal>
            </Popover>
          </Flex>
        ) : null}
      </Box>
      <Flex flexDirection="row-reverse">
        {showErrorState ? (
          <Alert
            status="error"
            bg="#FED7D7"
            borderRadius="10px"
            sx={{ textStyle: 'manropeHeading' }}
            fontWeight="500"
          >
            <AlertIcon color="#E53E3E" />
            {errorMessage}
          </Alert>
        ) : (
          <SkipValidateAnswer
            skipQuestion={skipQuestion}
            vallidateAnswer={vallidateAnswer}
            goToBackQuestion={goToBackQuestion}
          />
        )}
      </Flex>
    </Flex>
  );
}
