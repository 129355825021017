import React, { useState } from 'react';
import { Box, Flex, Text, Checkbox, Stack, Select, Alert, AlertIcon } from '@chakra-ui/react';

import SkipValidateAnswer from './SkipValidateAnswer';

export default function Default({ question, userAnswered, goToPreviousQuestion }) {
  // Initialize state to track the selected checkbox
  const [selectedOption, setSelectedOption] = useState(
    question.answers[0].AIAnswer ? question.answers[0].AIAnswer : '',
  );
  const [questionsAnswer, setquestionsAnswer] = useState('');
  const [showErrorState, setShowErrorState] = useState(false);
  const [errorMessage, setErrorMessage] = useState('')
  const mcqQuestion = question;
  const options = mcqQuestion.answers[0].options.map((value, index) => ({
    id: index + 1,
    value: value,
    label: value,
  }));
  const handleChange = (value) => {
    setShowErrorState(false)
    setSelectedOption(selectedOption === value ? '' : value);
    setquestionsAnswer(value);
  };
  const handleSelectoinChange = (event) => {
    setShowErrorState(false)
    setquestionsAnswer(event.target.value);
  };
  const vallidateAnswer = () => {
    let hasError = false;
    if(!questionsAnswer.length) {
      hasError = true;
      setShowErrorState(true)
      setErrorMessage(' Please answer the required fields')
    }
    if (!hasError) {
      let userans = {
        default: questionsAnswer,
      };
      userAnswered(userans);
    }
  };
  const skipQuestion = () => {
    console.log('skipQuestion');
  };
  const goToBackQuestion = () => {
    goToPreviousQuestion()
  }

  return (
    <Flex
      h="100%"
      overflow="hidden"
      flexDirection="column"
      justifyContent="space-between"
    >
      <Box>
        <Flex justifyContent="space-between" alignItems="center">
          <Text sx={{ textStyle: 'manropeHeading' }}>{mcqQuestion.text}</Text>
        </Flex>
        <Text
          sx={{ textStyle: 'manropeHeading' }}
          fontSize="14px"
          color="avenirGrey.400"
          lineHeight="16px"
          fontWeight="400"
          mb="24px"
          mt='8px'
        >
          {question.subText}
        </Text>
        {options.length < 5 ? (
          <Flex justifyContent="space-between" alignItems="end">
            <Box>
              <Stack>
                {options.map((option) => (
                  <Checkbox
                    key={option.id}
                    value={option.value}
                    isChecked={selectedOption === option.value}
                    onChange={() => handleChange(option.value)}
                    colorScheme="avenirSuitabilityColor"
                    sx={{
                      textStyle: 'manropeHeading',
                      '.chakra-checkbox__control': { borderRadius: '4px' },
                    }}
                  >
                    <Text ml="14px" color="avenirGrey.600">
                      {option.label}
                    </Text>
                  </Checkbox>
                ))}
              </Stack>
            </Box>
          </Flex>
        ) : (
          <Box position="relative" zIndex="10">
            <Select
              placeholder="Select option"
              onChange={handleSelectoinChange}
              w="240px"
              ml="1px"
            >
              {options.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </Select>
          </Box>
        )}
      </Box>
      <Flex flexDirection="row-reverse">
        {showErrorState ? (
          <Alert
            status="error"
            bg="#FED7D7"
            borderRadius="10px"
            sx={{ textStyle: 'manropeHeading' }}
            fontWeight="500"
          >
            <AlertIcon color="#E53E3E" />
            {errorMessage}
          </Alert>
        ) : (
          <SkipValidateAnswer
            skipQuestion={skipQuestion}
            vallidateAnswer={vallidateAnswer}
            goToBackQuestion={goToBackQuestion}
          />
        )}
      </Flex>
    </Flex>
  );
}
