import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  Box,
  Text,
  Flex,
  Image,
  Stack,
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Tooltip,
  IconButton,
  Tabs,
  TabList,
  Tab,
  Icon,
  OrderedList,
  ListItem,
  Skeleton,
  SkeletonText,
} from '@chakra-ui/react';
import { msalInstance } from '../../..//microsoftMSAL/msalConfig';
import { FiMoreVertical } from 'react-icons/fi';
import microsoftLogo from 'assets/img/SuitabilityReport/logos_microsoft-teams.png';
import googleLogo from 'assets/img/SuitabilityReport/logos_google-meet.png';
import zoomLogo from 'assets/img/SuitabilityReport/logos_zoom.png';
import { FiChevronDown, FiRotateCw } from 'react-icons/fi';
import { InfoOutlineIcon } from '@chakra-ui/icons';
import { MdFormatPaint, MdOutlineCollections } from 'react-icons/md';
import Calendar from 'react-calendar'; // Import the calendar
import 'react-calendar/dist/Calendar.css'; // Import calendar CSS for styling

import MeetingCard from './MeetingCard';
import { getUserClientList } from '../../../services/apiServices';

const MeetingsOverview = ({ meetingDetails, changeMeetingParams, loading }) => {
  const userInfo = useSelector((state) => state.usrinf);
  const [userClientList, setUserClientList] = useState([]);
  const [selectedTab, setSelectedTab] = useState('allMeetings');
  const [value, setValue] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const [sortedMeetingList, setSortedMeetingList] = useState(
    useState({ meetings: [] }),
  );
  const [sortedType, setSortedType] = useState('Date:  Newest to oldest');
  const handleSelectDate = (date) => {
    setValue(date);
    setIsOpen(false); // Close the dropdown after selection
    changeMeetingParams(selectedTab, date);
  };
  const microsoftlogout = () =>{
    msalInstance.logoutPopup({
      postLogoutRedirectUri: "/",
      mainWindowRedirectUri: "/" // Optional: After the popup logout, where should the main window redirect
    })
    .then(() => {
      window.location.href = '/suitability-report/meetings';
    })
    .catch(error => {
      window.location.href = '/suitability-report/meetings';
    });
  }
  const setSelectedTabIndex = (index) => {
    setSelectedTab(index === 0 ? 'allMeetings' : 'summarisedMeetings');
    let res = index === 0 ? 'allMeetings' : 'summarisedMeetings';
    changeMeetingParams(res, value);
  };
  const updateMeetingList = () => {
    changeMeetingParams('allMeetings');
  };
  const reloadMeetings = async () => {
    setValue('');
    setSortedType('Date:  Newest to oldest');
    changeMeetingParams('allMeetings');
  };
  const handleMenuItemClick = (selectedType) => {
    setSortedType(selectedType);
    let sortedMeetings = {
      meetings: [],
    };
    if (meetingDetails?.meetings?.length) {
      if (selectedType === 'Date: Oldest to newest') {
        sortedMeetings.meetings = meetingDetails.meetings.sort(
          (a, b) => new Date(a.date) - new Date(b.date),
        );
      } else {
        sortedMeetings.meetings = meetingDetails.meetings.sort(
          (a, b) => new Date(b.date) - new Date(a.date),
        );
      }
    }
    setSortedMeetingList(sortedMeetings);
  };
  useEffect(() => {
    let sortedMeetings = {
      meetings: [],
    };
    if (meetingDetails?.meetings?.length) {
      if (sortedType === 'Date: Oldest to newest') {
        sortedMeetings.meetings = meetingDetails.meetings.sort(
          (a, b) => new Date(a.date) - new Date(b.date),
        );
      } else {
        sortedMeetings.meetings = meetingDetails.meetings.sort(
          (a, b) => new Date(b.date) - new Date(a.date),
        );
      }
    }
    setSortedMeetingList(sortedMeetings);
  }, [meetingDetails]);
  const updateClientList = async () => {
    const res = await getUserClientList('', 200);
    setUserClientList(res.clients);
  };
  useEffect(() => {
    async function fetchData() {
      try {
        const res = await getUserClientList('', 200);
        setUserClientList(res.clients);
      } catch (error) {
        console.error('Error fetching client list:', error);
      }
    }
    fetchData();
  }, []);
  // Sample Data (replace with dynamic data as needed)
  return (
    <Box
      w="80vw"
      h="97%"
      overflow="auto"
      backgroundColor="white"
      height="97%"
      mt="20px"
      mb="15px"
      borderRadius="30px"
      p={5}
    >
      <Text sx={{ textStyle: 'dmsansNormal' }}>
        {userInfo.organisationName} / Meetings
      </Text>
      <Text sx={{ textStyle: 'dmsansHeading' }} mb="20px">
        Meetings Overview
      </Text>
      <Flex>
        <Flex
          bg="#FFF"
          w="320px"
          p="8px 11px"
          borderRadius="20px"
          sx={{
            boxShadow: '14px 17px 40px 4px rgba(112, 144, 176, 0.08)', // Apply custom box-shadow
          }}
          mr="20px"
          alignItems="center"
        >
          <Image
            src={microsoftLogo}
            alignSelf="center"
            justifySelf="center"
            mr="20px"
            w="45px"
            h="34px"
          />
          <Box>
            <Flex justifyContent="space-between" alignItems="center">
              <Flex alignItems="baseline">
                <Text sx={{ textStyle: 'dmsansNormal' }} color="#A3AED0">
                  {' '}
                  Microsoft Teams
                </Text>
                <Tooltip
                  bg="white"
                  color="black"
                  fontSize="md"
                  hasArrow
                  placement="top"
                  borderRadius="15px"
                  padding="12px 14px"
                  w="300px"
                  label={
                    <Box>
                      <Text
                        sx={{ textStyle: 'dmsansNormal' }}
                        color="black"
                        fontSize="12px"
                        fontWeight="700"
                        letterSpacing="-0.24px"
                        lineHeight="18px"
                      >
                        To record a meeting on Microsoft Teams:
                      </Text>
                      <OrderedList
                        sx={{ textStyle: 'interText' }}
                        color="black"
                        fontSize="12px"
                        fontWeight="400"
                        letterSpacing="-0.32px"
                        lineHeight="18px"
                        mt="5px"
                      >
                        <ListItem>
                          Start or join the meeting on Microsoft Teams.
                        </ListItem>
                        <ListItem>
                          In your meeting controls, select More
                          actions&nbsp;&gt;&nbsp;Record and
                          transcribe&nbsp;&gt;&nbsp;Start recording.
                        </ListItem>
                        <ListItem>
                          Once the call is over, your recording will
                          automatically be transmitted to Avenir.
                        </ListItem>
                      </OrderedList>
                    </Box>
                  }
                >
                  <IconButton
                    variant="ghost"
                    aria-label="Information"
                    icon={<InfoOutlineIcon />}
                    size="sm"
                    borderRadius="full"
                    cursor="default"
                    color="secondaryGray.600"
                  />
                </Tooltip>
              </Flex>
              <Menu>
                <MenuButton
                  as={IconButton}
                  icon={<FiMoreVertical />}
                  _hover={{ bg: 'white' }}
                  bg="white"
                  border="0px !important"
                  cursor="pointer"
                  p={0}
                  m={0}
                  minWidth="auto"
                />
                <MenuList
                  border="1px solid"
                  borderColor="avenirGrey.200"
                  borderRadius="10px"
                  minWidth="auto"
                  py="1" // Padding on top and bottom of the MenuList to avoid cutoffs
                  boxShadow="lg" // Adds a slight shadow for better visibility
                  bg="white"
                >
                  <MenuItem
                    _hover={{ bg: 'avenirGrey.100' }}
                    px={4}
                    borderRadius="8px" // Keep a slight radius for each MenuItem
                    fontFamily="'DM Sans', sans-serif"
                    fontWeight="500"
                    justifyContent="flex-start"
                    onClick={() => microsoftlogout()}
                    variant="ghost"
                    width="100%"
                    cursor="pointer"
                  >
                    Logout
                  </MenuItem>
                </MenuList>
              </Menu>
            </Flex>
            <Text
              sx={{ textStyle: 'dmsansNormal' }}
              color="#2B3674"
              fontSize="16px"
              fontWeight="700"
            >
              {' '}
              All recorded meetings on Teams will be loaded below
            </Text>
          </Box>
        </Flex>
        <Flex
          bg="#FFF"
          w="300px"
          p="8px 11px"
          borderRadius="20px"
          sx={{
            boxShadow: '14px 17px 40px 4px rgba(112, 144, 176, 0.08)', // Apply custom box-shadow
          }}
          mr="20px"
          alignItems="center"
        >
          <Image
            src={zoomLogo}
            alignSelf="center"
            justifySelf="center"
            mr="20px"
            w="60px"
            h="17px"
          />
          <Box>
            <Text sx={{ textStyle: 'dmsansNormal' }} color="#A3AED0">
              {' '}
              Zoom Meetings
            </Text>
            <Text
              sx={{ textStyle: 'dmsansNormal' }}
              color="#2B3674"
              fontSize="16px"
              fontWeight="700"
            >
              {' '}
              Coming Soon
            </Text>
          </Box>
        </Flex>
        <Flex
          bg="#FFF"
          w="300px"
          p="11px 14px"
          borderRadius="20px"
          sx={{
            boxShadow: '14px 17px 40px 4px rgba(112, 144, 176, 0.08)', // Apply custom box-shadow
          }}
          alignItems="center"
        >
          <Image
            src={googleLogo}
            alignSelf="center"
            justifySelf="center"
            mr="20px"
            w="45px"
            h="34px"
          />
          <Box>
            <Text sx={{ textStyle: 'dmsansNormal' }} color="#A3AED0">
              {' '}
              Google Meet
            </Text>
            <Text
              sx={{ textStyle: 'dmsansNormal' }}
              color="#2B3674"
              fontSize="16px"
              fontWeight="700"
            >
              {' '}
              Coming Soon
            </Text>
          </Box>
        </Flex>
      </Flex>
      <Tabs
        variant="unstyled"
        onChange={(index) => {
          setSelectedTabIndex(index);
        }}
        mt="10px"
        borderBottom="1px solid"
        borderColor="#E9EDF7"
      >
        <TabList>
          <Tab
            _selected={{
              color: '#2B3674',
              borderBottom: '4px solid',
              borderRadius: '2px',
              borderColor: 'avenirSuitabilityColor.600',
            }}
          >
            <Box display="flex" alignItems="center">
              <Icon
                as={MdOutlineCollections}
                mr={2}
                w="22px"
                h="22px"
                color="#2B3674"
              />{' '}
              <Text
                sx={{ textStyle: 'dmsansNormal' }}
                color="#2B3674"
                fontSize="16px"
                fontWeight="700"
                letterSpacing="-0.36px"
                lineHeight="32px"
              >
                All Meetings
              </Text>
            </Box>
          </Tab>
          <Tab
            _selected={{
              color: '#2B3674',
              borderBottom: '4px solid',
              borderRadius: '2px',
              borderColor: 'avenirSuitabilityColor.600',
            }}
          >
            <Box display="flex" alignItems="center">
              <Icon
                as={MdFormatPaint}
                mr={2}
                w="22px"
                h="22px"
                color="#2B3674"
              />{' '}
              {/* Replace with appropriate icon */}
              <Text
                sx={{ textStyle: 'dmsansNormal' }}
                color="#2B3674"
                fontSize="16px"
                fontWeight="700"
                letterSpacing="-0.36px"
                lineHeight="32px"
              >
                Summarised Meetings
              </Text>
            </Box>
          </Tab>
        </TabList>
      </Tabs>
      <Flex justifyContent="space-between" alignItems="center" mb={5} mt="20px">
        <Stack direction="row" spacing={4}>
          {/* All Meetings Dropdown */}
          <Menu isOpen={isOpen} onClose={() => setIsOpen(false)}>
            <MenuButton
              as={Button}
              rightIcon={<FiChevronDown />}
              sx={{ textStyle: 'dmsansNormal' }}
              onClick={() => setIsOpen(!isOpen)}
            >
              {/* {value ? 
              {value.toLocaleString('default', {
                month: 'long',
                year: 'numeric',
              })}{' '} :  'last 30 days'}  */}
              {value
                ? `${value.toLocaleString('default', {
                    month: 'long',
                    year: 'numeric',
                  })} `
                : 'last 30 days'}

              {/* Display selected month and year */}
            </MenuButton>
            <MenuList padding={0}>
              {/* Calendar only allows selection by year and month */}
              <Calendar
                onChange={handleSelectDate}
                value={value}
                showNavigation={true} // Show navigation controls for switching months
                view="year" // Start with year view
                maxDetail="year" // Disable day selection (month only)
              />
            </MenuList>
          </Menu>

          {/* Date Filter Dropdown */}
          <Menu>
            <MenuButton
              as={Button}
              rightIcon={<FiChevronDown />}
              variant="outline"
              sx={{ textStyle: 'dmsansNormal' }}
              color="#2B3674"
              fontWeight="400"
            >
              {sortedType}
            </MenuButton>
            <MenuList>
              <MenuItem
                onClick={() => handleMenuItemClick('Date: Oldest to newest')}
              >
                Date: Oldest to newest
              </MenuItem>
              <MenuItem
                onClick={() => handleMenuItemClick('Date: Newest to oldest')}
              >
                Date: Newest to oldest
              </MenuItem>
            </MenuList>
          </Menu>
          <Flex
            border="1px"
            borderColor="#E6E6E6"
            alignItems="center"
            justifyContent="center"
            p="4px 6px"
            borderRadius="12px"
            cursor="pointer"
            onClick={() => reloadMeetings()}
          >
            <Icon as={FiRotateCw} w="22px" h="20px" color="#2B3674" />{' '}
          </Flex>
        </Stack>
      </Flex>
      <Flex wrap="wrap" gap={4} ml="3px">
        {!loading
          ? Array.isArray(sortedMeetingList.meetings) &&
            sortedMeetingList.meetings.length > 0
            ? sortedMeetingList.meetings.map((meeting) => (
                <MeetingCard
                  key={meeting.meetingAccessId}
                  meeting={meeting}
                  userClientList={userClientList}
                  updateMeetingList={updateMeetingList}
                  updateClientList={updateClientList}
                />
              ))
            : null
          : Array.from({ length: 5 }, (_, index) => (
              <Box
                key={index} // Add a key to each element inside the loop
                p="16px"
                borderRadius="12px"
                border="1px solid #E2E8F0"
                w="350px"
                h="130px"
                boxShadow="sm"
              >
                {/* Top Section with Title and Icon */}
                <Flex justifyContent="space-between" alignItems="center">
                  <Skeleton height="20px" width="150px" borderRadius="md" />
                  <Skeleton height="20px" width="20px" borderRadius="full">
                    <IconButton
                      icon={<FiMoreVertical />}
                      aria-label="More Options"
                      variant="ghost"
                      isDisabled
                    />
                  </Skeleton>
                </Flex>

                {/* Date Section */}
                <SkeletonText
                  mt="12px"
                  noOfLines={1}
                  skeletonHeight="16px"
                  width="120px"
                />

                {/* Bottom Section with Button */}
                <Flex justifyContent="flex-end" mt="20px">
                  <Skeleton height="26px" width="120px" borderRadius="full">
                    <Button isLoading>Loading</Button>
                  </Skeleton>
                </Flex>
              </Box>
            ))}
      </Flex>
    </Box>
  );
};
export default MeetingsOverview;
