import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  Box,
  Button,
  Flex,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  VStack,
  Input,
  Skeleton,
  IconButton,
  SkeletonText,
  HStack,
  Icon,
  TabList,
  Tab,
} from '@chakra-ui/react';
import { LuPlusCircle } from 'react-icons/lu';
import { FiMoreVertical } from 'react-icons/fi';
// Custom components
import Card from 'components/card/Card';
//import files
import V1PreviewTemplate from '../components/V1PreviewTemplate';
import V1CreateNewClient from '../components/V1CreateNewClient';
import { MdOutlineSwitchLeft, MdOutlineCollections } from 'react-icons/md';
//Import Api's
import {
  createClient,
  getTempalteList,
  CreateReportAPI,
} from '../../../services/apiServices';

const V1NewReport = ({ AsssignNewReportDetails, UpdateNewReportState }) => {
  const userInfo = useSelector((state) => state.usrinf);
  const reduxClientLists = Object.values(
    useSelector((state) => state.clientList),
  );
  const [showPreview, setShowPreview] = useState(false);
  const [clientName, setClientName] = useState('');
  const [showCreateClientModal, setShowCreateClientModal] = useState(false);
  const [reportName, setReportName] = useState('');
  const [clientList] = useState(reduxClientLists);
  const [showClientList, setShowClientList] = useState(false);
  const [clientId, setClientId] = useState('');
  const [selecetedType, setSelectedType] = useState('');
  const [filteredCLientlist, setFilteredCLientlist] = useState([]);
  const [errorMessage, serErrorMessage] = useState('');
  const [templateList, setTemplateList] = useState([]);
  const [templateDetails, setTemplateDetails] = useState();
  const [selectedTab, setSelectedTab] = useState('Your firm’s templates');
  const [allTemplateList, setAllTemplateList] = useState([]);
  const [isPrivateReportsPresent, setIsPrivateReportsPresent] = useState(false);
  const [clientErrorMessage, setClientErrorMessage] = useState('');
  const [createdClientName, setCreatedClientName] = useState({
    firstname: '',
    lastname: '',
  });
  const handlePreviewClick = (details) => {
    setTemplateDetails(details);
    setShowPreview(true);
    serErrorMessage('');
  };
  const selectCard = (val, details) => {
    setTemplateDetails(details);
    setSelectedType(val);
    setShowPreview(false);
    serErrorMessage('');
  };
  const handleClientNameChange = (e) => {
    setClientErrorMessage('');
    let searchResult = e.target.value;
    setClientName(e.target.value);
    setShowClientList(true);
    if (!e.target.value) {
      setFilteredCLientlist(clientList.slice(0, 5));
      setClientId('');
    } else {
      const filteredfiveClients = clientList
        .filter((client) =>
          client.firstName.toLowerCase().includes(searchResult.toLowerCase()),
        )
        .sort((a, b) => {
          if (a.firstName.toLowerCase() === searchResult.toLowerCase())
            return -1;
          if (b.firstName.toLowerCase() === searchResult.toLowerCase())
            return 1;
          return 0;
        })
        .slice(0, 6); //
      setFilteredCLientlist(filteredfiveClients);
    }
  };
  const handleClientNameBlur = () => {
    setShowClientList(false);
  };
  const handleClose = () => {
    setShowPreview(false);
  };
  const handleReportNameChange = (e) => {
    setReportName(e.target.value);
    serErrorMessage('');
  };
  const updateReportState = async () => {
    if (selecetedType.length && reportName.length && clientId) {
      let res = {
        clientUID: clientId,
        templateUID: templateDetails.templateUID,
        reportName: reportName,
      };
      const response = await CreateReportAPI(res);
      if (response && response.status === 409) {
        serErrorMessage('Report Name already exists for the Client'); // Set error message if 409
      } else {
        let res = {
          reportUID: response,
          templateUID: templateDetails.templateUID,
          reportName: reportName,
        };
        AsssignNewReportDetails(res);
        UpdateNewReportState(2);
      }
    } else {
      if (!clientId) {
        if (clientName.length) {
          setClientErrorMessage(
            "Please click on 'Add a new Client' to add this Client on Avenir",
          );
        }
        serErrorMessage('Please select a client for this report.');
      } else if (!reportName.length) {
        serErrorMessage('Please enter a name for this report.');
      } else if (!selecetedType.length) {
        serErrorMessage('Please select a template.');
      }
    }
  };
  const addNewCleint = () => {
    let [clientfirstname, ...rest] = clientName.split(' ');
    let clientlastname = rest.join(' '); 
    setCreatedClientName({
      firstname: clientfirstname,
      lastname: clientlastname,
    });
    setShowCreateClientModal(true);
  };
  const closeClient = () => {
    setShowCreateClientModal(false);
  };
  const createNewClient = async (res) => {
    const uid = await createClient(res);
    setClientId(uid.clientUID);
    const fullName = `${res.firstName} ${res.lastName}`;
    setClientName(fullName);
    setShowCreateClientModal(false);
    setShowClientList(false);
    serErrorMessage('');
  };
  const selectedClient = (client) => {
    const fullName = `${client.firstName} ${client.lastName}`;
    setClientName(fullName);
    setClientId(client.clientUID);
    setShowClientList(false);
    serErrorMessage('');
  };
  const setSelectedTabIndex = (index) => {
    setSelectedTab(index === 0 ? 'Your firm’s templates' : 'Avenir templates');
    if (index === 0) {
      const filteredTemplates = allTemplateList.filter(
        (template) => template.privateTemplate === true,
      );
      setTemplateList(filteredTemplates);
    } else {
      const filteredTemplates = allTemplateList.filter(
        (template) => template.privateTemplate === false,
      );
      setTemplateList(filteredTemplates);
    }
  };
  useEffect(() => {
    getAllTemplateLlist();
  }, []);
  const getAllTemplateLlist = async () => {
    const res = await getTempalteList();
    setAllTemplateList(res.templates);
    const isPresent = res.templates.filter(
      (template) => template.privateTemplate === true,
    ).length;
    setIsPrivateReportsPresent(isPresent);
    if (isPresent) {
      const filteredTemplates = res.templates.filter(
        (template) => template.privateTemplate === true,
      );
      setTemplateList(filteredTemplates);
      setSelectedTab('Your firm’s templates');
    } else {
      const filteredTemplates = res.templates.filter(
        (template) => template.privateTemplate === false,
      );
      setTemplateList(filteredTemplates);
      setSelectedTab('Avenir templates');
    }
  };
  return (
    <Box>
      <Box w="80vw" p="36px" h="100vh" overflowY="scroll">
        <Text
          sx={{ textStyle: 'dmsansNormal' }}
          color="#707EAE"
          fontWeight="500"
          fontSize="14px"
          lineHeight="24px"
        >
          {userInfo.organisationName} / New Report
        </Text>
        <Text
          sx={{ textStyle: 'dmsansNormal' }}
          fontSize="34px"
          color="#2B3674"
          mb="5px"
          lineHeight="42px"
        >
          New Report
        </Text>
        <Flex direction="column" align="center" p="24px" position="relative">
          <Box
            h="30vh"
            bgGradient="linear(to-b, avenirSuitabilityColor.600, avenirSuitabilityColor.600)"
            position="absolute"
            w="100%"
            borderRadius="20px"
          />
          <Tabs
            variant="unstyled"
            mt={{ base: '60px', md: '50px' }}
            zIndex="0"
            display="flex"
            flexDirection="column"
          >
            <TabPanels mt="24px" maxW={{ md: '90%', lg: '100%' }} mx="auto">
              <TabPanel
                w={{ sm: '330px', md: '700px', lg: '850px' }}
                p="0px"
                mx="auto"
              >
                <Card p="30px">
                  <Box>
                    <VStack align="flex-start" spacing={4}>
                      <Text
                        sx={{ textStyle: 'dmsansNormal' }}
                        fontSize="24px"
                        fontWeight="700"
                        letterSpacing="-0.48px"
                        color="#2B3674"
                      >
                        Configure your report
                      </Text>
                      <Flex w="100%" justifyContent="space-between">
                        <Box w="48%">
                          <Text
                            sx={{ textStyle: 'dmsansNormal' }}
                            fontWeight="500"
                            fontSize="14px"
                            color="#1B2559"
                            letterSpacing="-0.28px"
                            lineHeight="28px"
                          >
                            Report Name
                          </Text>
                          <Input
                            value={reportName}
                            placeholder="Suitability 02-04-2024"
                            borderRadius="8px"
                            mt="8px"
                            focusBorderColor="avenirSuitabilityColor.600"
                            borderColor={'#E6E6E6'}
                            onChange={handleReportNameChange}
                          />
                        </Box>
                        <Box
                          w="48%"
                          onBlur={() => setTimeout(handleClientNameBlur, 300)}
                        >
                          <Text
                            sx={{ textStyle: 'dmsansNormal' }}
                            fontWeight="500"
                            fontSize="14px"
                            color="#1B2559"
                            letterSpacing="-0.28px"
                            lineHeight="28px"
                          >
                            Which client is it for?
                          </Text>
                          <Input
                            value={clientName}
                            placeholder="Add a new client"
                            borderRadius="8px"
                            mt="8px"
                            borderColor={
                              clientErrorMessage.length ? '#B20D30' : '#E6E6E6'
                            }
                            focusBorderColor={
                              clientErrorMessage.length
                                ? '#B20D30'
                                : 'avenirSuitabilityColor.600'
                            }
                            onChange={handleClientNameChange}
                            onFocus={handleClientNameChange}
                            onKeyDown={(e) => {
                              if (e.key === 'Enter') addNewCleint();
                            }}
                          />
                          {clientErrorMessage.length ? (
                            <Text
                              mt="2px"
                              sx={{ textStyle: 'manropeHeading' }}
                              color="#B20D30"
                              fontSize="12px"
                              fontWeight="600"
                            >
                              {clientErrorMessage}
                            </Text>
                          ) : null}
                          {showClientList ? (
                            <Box
                              p="10px"
                              mt="4px"
                              boxShadow="md"
                              bg="white"
                              w="300px"
                              borderRadius="16px"
                              position="absolute"
                              zIndex="123"
                            >
                              <VStack align="start">
                                <HStack
                                  spacing={2}
                                  onClick={addNewCleint}
                                  cursor="pointer"
                                  p="5px 10px"
                                >
                                  <Icon
                                    as={LuPlusCircle}
                                    boxSize="17px"
                                    color="#1B2559"
                                  />
                                  <Text
                                    color="#1B2559"
                                    sx={{ textStyle: 'dmsansNormal' }}
                                    fontWeight="400"
                                    fontSize="14px"
                                  >
                                    Add a new client
                                  </Text>
                                </HStack>
                                {filteredCLientlist.length > 0 ? (
                                  <Flex flexDirection="column" w="100%">
                                    {filteredCLientlist
                                      .map((client, index) => (
                                        <Text
                                          key={client.clientUID} // Use clientUID as a unique key
                                          cursor="pointer"
                                          color="#1B2559"
                                          sx={{ textStyle: 'dmsansNormal' }}
                                          fontWeight="400"
                                          fontSize="14px"
                                          onClick={() => selectedClient(client)}
                                          p="5px 10px"
                                          _hover={{
                                            backgroundColor: 'avenirGrey.200',
                                          }}
                                        >
                                          {client.firstName} {client.lastName}
                                        </Text>
                                      ))}
                                  </Flex>
                                ) : null}
                              </VStack>
                            </Box>
                          ) : null}
                        </Box>
                      </Flex>
                      <Box w="100%" mt="10px">
                        <Text
                          sx={{ textStyle: 'dmsansNormal' }}
                          fontWeight="500"
                          fontSize="14px"
                          color="#1B2559"
                          letterSpacing="-0.28px"
                          lineHeight="28px"
                          mb="8px"
                        >
                          Select your template
                        </Text>
                        <Text
                          sx={{ textStyle: 'dmsansNormal' }}
                          fontWeight="400"
                          fontSize="14px"
                          color="avenirGrey.500"
                          lineHeight="20px"
                          letterSpacing="-0.28px"
                        >
                          Choose a template to define the report’s structure,
                          required files, and content generated by Avenir.
                        </Text>
                      </Box>
                      <Tabs
                        variant="unstyled"
                        onChange={(index) => {
                          setSelectedTabIndex(index);
                        }}
                        mt="10px"
                        borderBottom="1px solid"
                        borderColor="#E9EDF7"
                        w="100%"
                      >
                        <TabList>
                          {isPrivateReportsPresent ? (
                            <Tab
                              _selected={{
                                color: '#2B3674',
                                borderBottom: '4px solid',
                                borderRadius: '2px',
                                borderColor: 'avenirSuitabilityColor.600',
                              }}
                            >
                              <Box display="flex" alignItems="center">
                                <Icon
                                  as={MdOutlineCollections}
                                  mr={2}
                                  w="22px"
                                  h="22px"
                                  color="#2B3674"
                                />{' '}
                                <Text
                                  sx={{ textStyle: 'dmsansNormal' }}
                                  color="#2B3674"
                                  fontSize="16px"
                                  fontWeight="700"
                                  letterSpacing="-0.36px"
                                  lineHeight="32px"
                                >
                                  Your firm’s templates
                                </Text>
                              </Box>
                            </Tab>
                          ) : null}
                          <Tab
                            _selected={{
                              color: '#2B3674',
                              borderBottom: '4px solid',
                              borderRadius: '2px',
                              borderColor: 'avenirSuitabilityColor.600',
                            }}
                          >
                            <Box display="flex" alignItems="center">
                              <Icon
                                as={MdOutlineCollections}
                                mr={2}
                                w="22px"
                                h="22px"
                                color="#2B3674"
                              />{' '}
                              {/* Replace with appropriate icon */}
                              <Text
                                sx={{ textStyle: 'dmsansNormal' }}
                                color="#2B3674"
                                fontSize="16px"
                                fontWeight="700"
                                letterSpacing="-0.36px"
                                lineHeight="32px"
                              >
                                Avenir templates
                              </Text>
                            </Box>
                          </Tab>
                        </TabList>
                      </Tabs>
                      <Flex
                        wrap="wrap"
                        gap={6}
                        w="100%"
                        justifyContent="flex-start"
                      >
                        {Array.isArray(allTemplateList) &&
                        allTemplateList.length > 0 ? (
                          templateList.map((template, index) => (
                            <Card
                              key={template.templateUID}
                              p="18px 14px"
                              borderRadius="12px"
                              border="1px solid #E2E8F0"
                              display="flex"
                              flexDirection="column"
                              justifyContent="space-between"
                              w="280px"
                              h="180px"
                              borderColor={
                                selecetedType === template.templateName
                                  ? 'avenirSuitabilityColor.600'
                                  : '#F9F9F9'
                              }
                              _hover={{
                                boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                              }}
                              onClick={() =>
                                selectCard(template.templateName, template)
                              }
                              cursor="pointer"
                            >
                              <Box alignItems="center" justifyContent="center">
                                <Flex
                                  h="31px"
                                  w="31px"
                                  mb="10px"
                                  backgroundColor="#F6F8FD"
                                  borderRadius="50%"
                                  justifyContent="center"
                                  alignItems="center"
                                >
                                  <MdOutlineSwitchLeft
                                    style={{
                                      height: '20px',
                                      width: '20px',
                                      color: '#4E13C3',
                                    }}
                                  />
                                </Flex>
                                <Flex
                                  justify="space-between"
                                  align="center"
                                  mb="12px"
                                >
                                  <Text
                                    sx={{ textStyle: 'dmsansText' }}
                                    fontWeight="700"
                                    fontSize="18px"
                                    color="#1B2559"
                                  >
                                    {template.templateName}
                                  </Text>
                                </Flex>
                                <Text
                                  sx={{ textStyle: 'dmsansNormal' }}
                                  fontWeight="400"
                                  fontSize="14px"
                                  color="#A3AED0"
                                  letterSpacing="-0.28px"
                                >
                                  {template.templateDescription}
                                </Text>
                              </Box>
                              <Flex
                                alignItems="center"
                                justifyContent="flex-end"
                              >
                                <Button
                                  variant="link"
                                  color="avenirSuitabilityColor.600"
                                  sx={{ textStyle: 'dmsansText' }}
                                  mt="12px"
                                  fontWeight="500"
                                  fontSize="14px"
                                  textDecoration="underline"
                                  onClick={(e) => {
                                    e.stopPropagation(); // Prevents card onClick from being triggered
                                    handlePreviewClick(template);
                                  }}
                                >
                                  Preview Template
                                </Button>
                              </Flex>
                            </Card>
                          ))
                        ) : (
                          <Flex>
                            {Array.from({ length: 2 }, (_, index) => (
                              <Box
                                key={index}
                                p="16px"
                                borderRadius="12px"
                                border="1px solid #E2E8F0"
                                w="350px"
                                h="130px"
                                boxShadow="sm"
                                ml="20px"
                              >
                                <Flex
                                  justifyContent="space-between"
                                  alignItems="center"
                                >
                                  <Skeleton
                                    height="20px"
                                    width="150px"
                                    borderRadius="md"
                                  />
                                  <Skeleton
                                    height="20px"
                                    width="20px"
                                    borderRadius="full"
                                  >
                                    <IconButton
                                      icon={<FiMoreVertical />}
                                      aria-label="More Options"
                                      variant="ghost"
                                      isDisabled
                                    />
                                  </Skeleton>
                                </Flex>

                                {/* Date Section */}
                                <SkeletonText
                                  mt="12px"
                                  noOfLines={1}
                                  skeletonHeight="16px"
                                  width="120px"
                                />

                                {/* Bottom Section with Button */}
                                <Flex justifyContent="flex-end" mt="20px">
                                  <Skeleton
                                    height="26px"
                                    width="120px"
                                    borderRadius="full"
                                  >
                                    <Button isLoading>Loading</Button>
                                  </Skeleton>
                                </Flex>
                              </Box>
                            ))}
                          </Flex>
                        )}
                      </Flex>
                      {errorMessage.length ? (
                        <Flex
                          w="100%"
                          justifyContent="flex-end"
                          mt="2px"
                          sx={{ textStyle: 'manropeHeading' }}
                          color="#B20D30"
                          fontSize="12px"
                          fontWeight="600"
                        >
                          {errorMessage}
                        </Flex>
                      ) : (
                        <Flex
                          w="100%"
                          justifyContent="flex-end"
                          mt="14px"
                        ></Flex>
                      )}
                      <Flex w="100%" justifyContent="flex-end" mt="-14px">
                        <Button
                          color="white"
                          bg="avenirSuitabilityColor.600"
                          p="11px 22px"
                          borderRadius="12px"
                          fontSize="14px"
                          sx={{ textStyle: 'dmsansNormal' }}
                          _hover={{ bg: 'avenirSuitabilityColor.600' }}
                          onClick={updateReportState}
                        >
                          Start Report
                        </Button>
                      </Flex>
                    </VStack>
                  </Box>
                </Card>
              </TabPanel>
            </TabPanels>
          </Tabs>
        </Flex>
      </Box>
      {showPreview ? (
        <V1PreviewTemplate
          selectCard={selectCard}
          templateDetails={templateDetails}
          handleClose={handleClose}
        ></V1PreviewTemplate>
      ) : null}
      {showCreateClientModal ? (
        <V1CreateNewClient
          closeClient={closeClient}
          createNewClient={createNewClient}
          createdClientName={createdClientName}
        ></V1CreateNewClient>
      ) : null}
    </Box>
  );
};

export default V1NewReport;
