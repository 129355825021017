import { Flex, Button } from '@chakra-ui/react';
import V1SideNav from '../components/V1SideNav';
import MeetingsOverview from '../components/MeetingsOverview';
import MicrosoftLogin from '../components/MicrosoftLogin';
import { msalInstance } from '../../../microsoftMSAL/msalConfig';
import { getMicrosoftMeetings } from '../../../services/apiServices';
import { useEffect, useState } from 'react';
import { setMicrosoftAuthentication } from 'reducers/actions';
import { useDispatch } from 'react-redux';

const SuitabilityClients = () => {
  const dispatch = useDispatch();
  const [isMicrosoftUserLoggedIn, setIsMicrosoftUserLoggedIn] = useState(false);
  const [microsoftIdToken, setMicrosoftIdToken] = useState('');
  const [loadingMeetings, setLoadingMeetings] = useState(false);
  const [meetingParam, setMeetingParam] = useState({
    summarizedMeetingsOnly: false,
  });
  const [account, setAccount] = useState(null); // Store the active account
  const [meetingDetails, setMeetingDetails] = useState([]);

  // Define the scopes you need
  const tokenRequest = {
    scopes: [
      'user.read',
      'OnlineMeetingRecording.Read.All',
      'OnlineMeetings.Read',
      'Calendars.Read',
      'OnlineMeetingTranscript.Read.All',
    ],
    account: account, // Pass the account object explicitly
  };

  const initializeMsal = async () => {
    try {
      await msalInstance.initialize(); // This ensures MSAL is initialized before making API calls
    } catch (error) {
      console.error('Error initializing MSAL:', error);
    }
  };

  const setActiveAccount = () => {
    const accounts = msalInstance.getAllAccounts();
    if (accounts.length > 0) {
      const activeAccount = accounts[0]; // Get the first available account
      msalInstance.setActiveAccount(activeAccount); // Set the active account
      setAccount(activeAccount); // Store the account in the state
    } else {
      console.log('No accounts found in MSAL cache');
    }
  };

  const getAccessToken = async () => {
    try {
      if (account) {
        const tokenResponse = await msalInstance.acquireTokenSilent(
          tokenRequest,
        );
        setMicrosoftIdToken(tokenResponse.accessToken);
        const res = {
          mid: tokenResponse.accessToken,
        };
        dispatch(setMicrosoftAuthentication(res));
        setIsMicrosoftUserLoggedIn(true);
      } else {
        console.error('No active account set.');
      }
    } catch (error) {
      console.error('Error getting token silently:', error);
      setMicrosoftIdToken('');
      setIsMicrosoftUserLoggedIn(false);
      // Optionally handle fallback here, like login popup
    }
  };
  const changeMeetingParams = (type, date) => {
    // Capture the month and year
    const month = date ? date.getMonth() + 1 : '';
    const year = date ? date.getFullYear() : '';
    let res = {
      summarizedMeetingsOnly: type === 'summarisedMeetings' ? true : false,
      month: month,
      year: year,
    };
    setMeetingParam(res);
    getMeetings(res);
  };

  const getMeetings = async (meetingRes) => {
    if (microsoftIdToken) {
      setLoadingMeetings(true);
      try {
        if (meetingRes) {
          const res = await getMicrosoftMeetings(microsoftIdToken, meetingRes);
          setMeetingDetails(res);
        } else {
          const res = await getMicrosoftMeetings(
            microsoftIdToken,
            meetingParam,
          );
          setMeetingDetails(res);
        }
        
      } catch (error) {
        console.error('Error fetching meetings:', error);
      } finally {
        setLoadingMeetings(false);
      }
    }
  };

  useEffect(() => {
    const initializeMsalAndFetchData = async () => {
      await initializeMsal(); // Initialize MSAL instance first
      const accounts = msalInstance.getAllAccounts();
      if (accounts.length > 0) {
        setActiveAccount(); // Set the active account
      } else {
        setMicrosoftIdToken('');
      }
    };

    initializeMsalAndFetchData();
  }, []);

  useEffect(() => {
    if (account) {
      getAccessToken(); // Get the access token once the account is set
    }
  }, [account]); // Call getAccessToken only when the account is set

  useEffect(() => {
    if (microsoftIdToken) {
      getMeetings(); // Fetch meetings when the token is available
    }
  }, [microsoftIdToken]);

  return (
    <Flex h="100vh">
      <V1SideNav />
      {isMicrosoftUserLoggedIn ? (
        <MeetingsOverview
          key="meetings-overview"
          loading={loadingMeetings}
          meetingDetails={meetingDetails}
          changeMeetingParams={changeMeetingParams}
        />
      ) : (
        <MicrosoftLogin />
      )}
    </Flex>
  );
};

export default SuitabilityClients;
