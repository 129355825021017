import React from 'react';
import { Box } from '@chakra-ui/react';
import { useLocation } from 'react-router-dom';
import MyReports from '../../views/suitabilityReportViews/V1report/MyReports'; // Adjust the path as necessary
import SuitabilityAccountSettings from '../../views/suitabilityReportViews/V1report/SuitabilityAccountSettings';
// import SuitabilityClients from '../../views/suitabilityReportViews/V1report/SuitabilityClients'
import SuitabilituMeetings from '../../views/suitabilityReportViews/V1report/SuitabilityMeetings';
import EditMeeting from '../../views/suitabilityReportViews/V1report/EditMeeting'

export function SuitabilityReport() {
  const location = useLocation();

  return (
    <Box>
      {location.pathname === '/suitability-report/my-reports' ? (
        <MyReports />
      ) : location.pathname === '/suitability-report/account-settings' ? (
        <SuitabilityAccountSettings /> /*: location.pathname === '/suitability-report/clients' ? (
        <SuitabilityClients />
      )*/
      ) : location.pathname === '/suitability-report/meetings/edit-meeting' ? (
        <EditMeeting />
      ) : location.pathname === '/suitability-report/meetings' ? (
        <SuitabilituMeetings />
      ) : (
        <MyReports />
      )}
    </Box>
  );
}
