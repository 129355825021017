import React from 'react';

import { Icon } from '@chakra-ui/react';
import { MdLock, MdOutlineShoppingCart } from 'react-icons/md';

// Admin Imports
import AvenirDashboardDefault from 'views/admin/dashboards/avenirDefault';

//Value Mapping

// Auth Imports
import ForgotPasswordCentered from 'views/auth/forgotPassword/ForgotPasswordCentered.jsx';
import ForgotPasswordDefault from 'views/auth/forgotPassword/ForgotPasswordDefault.jsx';
import SignInCentered from 'views/auth/signIn/SignInCentered.jsx';
// import SignInDefault from 'views/auth/signIn/SignInDefault.jsx';
import SignUpCentered from 'views/auth/signUp/SignUpCentered.jsx';
// import SignUpDefault from 'views/auth/signUp/SignUpDefault.jsx';
import VerificationCentered from 'views/auth/verification/VerificationCentered.jsx';
import VerificationDefault from 'views/auth/verification/VerificationDefault.jsx';
// Suitability Reports
import MyReports from 'views/suitabilityReportViews/V1report/MyReports';
import SuitabilityAccountSettings from 'views/suitabilityReportViews/V1report/SuitabilityAccountSettings';
// import SuitabilityClients from 'views/suitabilityReportViews/V1report/SuitabilityClients'
import SuitabilityMeetings from 'views/suitabilityReportViews/V1report/SuitabilityMeetings'

const routes = [
  // Value Mapping
  {
    name: 'ValueMapping',
    path: '/value-mapping',
    icon: (
      <Icon
        as={MdOutlineShoppingCart}
        width="20px"
        height="20px"
        color="inherit"
      />
    ),
    collapse: true,
    items: [
      {
        name: 'Questions',
        layout: '/value-mapping',
        path: '/questions',
        component: <AvenirDashboardDefault />,
        secondary: true,
      },
    ],
  },
  //Suitability report
  {
    name: 'SuitabilityReport',
    path: '/suitability-report',
    icon: (
      <Icon
        as={MdOutlineShoppingCart}
        width="20px"
        height="20px"
        color="inherit"
      />
    ),
    collapse: true,
    items: [
      {
        name: 'My Reports',
        path: '/my-reports',
        component: <MyReports />,
      },
      {
        name: 'Account Settings',
        path: '/account-settings',
        component: <SuitabilityAccountSettings />,
      },
      {
        name: 'My Clients',
        path: '/meetings',
        component: <SuitabilityMeetings />,
      },
      {
        name: 'My Clients',
        path: '/meetings/edit-meeting',
        component: <SuitabilityMeetings />,
      },
      // {
      //   name: "My Clients",
      //   path: '/clients',
      //   component: <SuitabilityClients/>
      // }
    ],
  },
  // --- Authentication ---
  {
    name: 'Authentication',
    path: '/auth',
    icon: <Icon as={MdLock} width="20px" height="20px" color="inherit" />,
    collapse: true,
    items: [
      // --- Sign In ---
      {
        name: 'Sign In',
        path: '/sign-in',
        collapse: true,
        items: [
          // {
          //   name: 'Default',
          //   layout: '/auth',
          //   path: '/sign-in/default',
          //   component: <SignInDefault />,
          // },
          {
            name: 'Centered',
            layout: '/auth',
            path: '/sign-in',
            component: <SignInCentered />,
          },
        ],
      },
      // --- Sign Up ---
      {
        name: 'Sign Up',
        path: '/avenir-suitability-report-X23498ubcvbys-a32',
        collapse: true,
        items: [
          // {
          //   name: 'Default',
          //   layout: '/auth',
          //   path: '/sign-up/default',
          //   component: <SignUpDefault />,
          // },
          {
            name: 'Centered',
            layout: '/auth',
            path: '/avenir-suitability-report-X23498ubcvbys-a32',
            component: <SignUpCentered />,
          },
        ],
      },
      // --- Verification ---
      {
        name: 'Verification',
        path: '/verification',
        collapse: true,
        items: [
          {
            name: 'Default',
            layout: '/auth',
            path: '/verification/default',
            component: <VerificationDefault />,
          },
          {
            name: 'Centered',
            layout: '/auth',
            path: '/verification/centered',
            component: <VerificationCentered />,
          },
        ],
      },
      // --- Forgot Password ---
      {
        name: 'Forgot Password',
        path: '/forgot-password',
        collapse: true,
        items: [
          {
            name: 'Default',
            layout: '/auth',
            path: '/forgot-password/default',
            component: <ForgotPasswordDefault />,
          },
          {
            name: 'Centered',
            layout: '/auth',
            path: '/forgot-password/centered',
            component: <ForgotPasswordCentered />,
          },
        ],
      },
      //Payments
      {
        name: 'Subscribe',
        path: '/subscribe',
        icon: (
          <Icon
            as={MdOutlineShoppingCart}
            width="20px"
            height="20px"
            color="inherit"
          />
        ),
      },
      // Page Not FOund
      {
        name: 'Page Not Found',
        path: '/error',
        icon: (
          <Icon
            as={MdOutlineShoppingCart}
            width="20px"
            height="20px"
            color="inherit"
          />
        ),
      },
    ],
  },
];

export default routes;
